define('loftus/models/user', ['exports', 'ember-data', 'loftus/models/authorizes', 'ember-api-actions'], function (exports, _emberData, _authorizes, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authorizes.default.extend({
    code: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    pickStats: _emberData.default.attr('', { readOnly: true }),
    jwt: _emberData.default.attr('string', { readOnly: true }),
    local: _emberData.default.attr('boolean', { readOnly: true }),
    mustShip: _emberData.default.attr('boolean'),
    canShort: _emberData.default.attr('boolean'),
    handleSpecialProcessing: _emberData.default.attr('boolean'),
    name: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    terminatedOn: _emberData.default.attr('date'),
    cardDigits: _emberData.default.attr('string'),
    score: _emberData.default.attr('number', { readOnly: true }),
    notifications: _emberData.default.hasMany('notification'),
    documents: _emberData.default.hasMany('document', { inverse: 'user' }),
    unmatchedReceipts: Ember.computed('documents.@each.url', 'documents.@each.doReview', function () {
      return this.get('documents').filterBy('review');
    }),
    unmatchedReceipt: Ember.computed('unmatchedReceipts', function () {
      return this.get('unmatchedReceipts.firstObject');
    }),

    unreadNotifications: Ember.computed('notifications.@each.readAt', function () {
      return this.get('notifications').filterBy('readAt', null).filterBy('splash', true);
    }),
    unreadNotification: Ember.computed('unreadNotifications', function () {
      return this.get('unreadNotifications.firstObject');
    }),
    attendanceTier: _emberData.default.attr({ readOnly: true }),
    department: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),
    pin: _emberData.default.attr('string'),
    pinConfirmation: _emberData.default.attr('string'),
    temp: _emberData.default.attr('boolean'),
    displayName: _emberData.default.attr('string'),
    displayLast: Ember.computed('displayName', 'firstName', 'lastName', function () {
      if (this.get('lastName')) {
        return (this.get('displayName') || this.get('firstName')) + ' ' + this.get('lastName').slice(0, 1);
      }
    }),
    displayFull: Ember.computed('displayName', 'firstName', 'lastName', function () {
      if (this.get('lastName')) {
        return (this.get('displayName') || this.get('firstName')) + ' ' + this.get('lastName');
      }
    }),
    cellPhone: _emberData.default.attr('string'),
    extension: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    supervisor: _emberData.default.belongsTo('user', { inverse: 'supervisedUsers' }),
    station: _emberData.default.belongsTo('station'),
    salesRep: _emberData.default.belongsTo('sales-rep'),
    supervisedUsers: _emberData.default.hasMany('user', { inverse: 'supervisor' }),
    projectBatches: _emberData.default.hasMany('project-batch'),
    projectUsers: _emberData.default.hasMany('project-user'),
    projects: _emberData.default.hasMany('project'),
    userZones: _emberData.default.hasMany('user-zone'),
    zones: _emberData.default.hasMany('zone'),
    userTasks: _emberData.default.hasMany('user-task'),
    tasks: _emberData.default.hasMany('task'),
    timeClocks: _emberData.default.hasMany('time-clock', { inverse: 'user' }),
    timeClock: _emberData.default.belongsTo('time-clock'),
    recentTimeClocks: _emberData.default.attr({ readOnly: true }),
    timeAttendanceTier: _emberData.default.attr({ readOnly: true }),
    issues: _emberData.default.hasMany('issue', { inverse: 'user' }),
    permittedActions: _emberData.default.attr({ readOnly: true }),
    assignedPermissions: _emberData.default.hasMany('assigned-permission'),
    approvedOrders: _emberData.default.hasMany('order', { inverse: 'user' }),
    userRoles: _emberData.default.hasMany('user-role'),
    roles: _emberData.default.hasMany('role'),
    permissions: _emberData.default.hasMany('permission'),
    grantedPermissions: Ember.computed.mapBy('permissions', 'name'),
    loadPerformance: (0, _emberApiActions.memberAction)({ path: 'performance', type: 'get' }),
    loadCalls: (0, _emberApiActions.memberAction)({ path: 'calls', type: 'get' }),
    userData: (0, _emberApiActions.memberAction)({ path: 'data', type: 'get' }),
    getScore: (0, _emberApiActions.memberAction)({ path: 'score', type: 'get' }),
    buttonText: Ember.computed('hasDirtyAttributes', function () {
      if (this.get('hasDirtyAttributes')) {
        return 'Save User';
      } else {
        return 'User Saved';
      }
    })

  });
});