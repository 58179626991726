define('loftus/models/quote-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    overridePrice: _emberData.default.attr('number'),
    position: _emberData.default.attr('number'),
    canInflate: Ember.computed.alias('item.inflate'),
    prop65: Ember.computed('item.prop65', function () {
      return this.get('item.prop65') === 'label_required' || this.get('item.prop65') === 'restricted';
    }),
    inflated: _emberData.default.attr('boolean'),
    specialOrder: _emberData.default.attr('boolean'),
    comments: _emberData.default.attr('string'),
    commentClass: Ember.computed('comments', function () {
      return this.get('comments') && this.get('comments.length') > 0 ? 'comment-present' : 'comment-empty';
    }),
    displayComments: Ember.computed('comments', function () {
      if (this.get('comments')) {
        return this.get('comments').split(';').map(function (item) {
          return item.trim();
        });
      }
    }),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    owner: Ember.computed.alias('quote'),
    quote: _emberData.default.belongsTo('quote'),
    item: _emberData.default.belongsTo('item'),
    title: _emberData.default.attr('string'),
    defaultTitle: Ember.computed('inflated', 'item.title', function () {
      if (this.get('inflated')) {
        return 'INFLATED - ' + this.get('item.title');
      } else {
        return this.get('item.title');
      }
    }),
    upsoldUserId: _emberData.default.attr('number'),
    upsoldAt: _emberData.default.attr('date'),
    sku: Ember.computed.alias('item.sku'),
    validationClass: Ember.computed('duplicated', function () {
      if (this.get('duplicated')) {
        return 'invalid-duplicate';
      } else {
        return 'valid';
      }
    }),
    duplicated: Ember.computed('owner.quoteItems.@each.sku', 'sku', function () {
      var _this = this;
      var dupe = this.get('owner.quoteItems').reduce(function (acc, value) {
        if (value.get('sku') === _this.get('sku') && value.get('id') !== _this.get('id') && !_this.get('item.allowDuplicate')) {
          acc.push(value);
        }
        return acc;
      }, []);

      return dupe.length > 0;
    }),
    upsold: Ember.computed('upsoldUserId', function () {
      return !!this.get('upsoldUserId');
    }),
    extCost: Ember.computed('quantity', 'item.averageCost', function () {
      return this.get('quantity') * this.get('item.averageCost');
    }),
    baseCost: Ember.computed('item.averageCost', 'item.lastCost', function () {
      return Math.max(this.get('item.averageCost'), this.get('item.lastCost'));
    }),
    basePrice: Ember.computed('owner.customer.customerType', function () {
      if (this.get('inflated')) {
        console.log('inflated');
        return this.get('item.inflatedPrice');
      } else if (this.get('owner.customer.customerType') === 'wholesale') {
        console.log('wholesale');
        return this.get('item.wholesale') || this.get('item.retail');
      } else if (this.get('owner.customer.customerType') === 'preferred') {
        console.log('preferred');
        return this.get('item.preferred');
      } else if (this.get('owner.customer.customerType') === 'premium') {
        console.log('premium');
        return this.get('item.premium');
      } else if (this.get('owner.customer.customerType') === 'partner') {
        console.log('partner');
        return this.get('item.partner') || this.get('item.premium');
      } else if (this.get('owner.customer.customerType') === 'grocery') {
        console.log('grocery');
        return this.get('item.grocery') || this.get('item.premium');
      } else if (this.get('owner.customer.customerType') === 'affiliate') {
        console.log('affiliate');
        if (this.get('item.affiliatePrice')) {
          console.log('aa');
          return this.get('item.affiliatePrice');
        } else {
          return Math.round(100 * this.get('item.wholesale') / 0.92) / 100;
        }
      } else {
        return this.get('item.wholesale');
      }
    }),
    specialPrice: Ember.computed('owner.origin', 'item.specialPrice', function () {
      if (this.get('owner.origin') === 'EB-2018') {
        return this.get('item.specialPrice');
      } else if (this.get('owner.origin') === 'EB-2018 SHOW') {
        return this.get('item.specialPrice') / 1.05;
      }
    }),
    oversold: Ember.computed('quantity', 'maxQuantity', function () {
      return this.get('quantity') > this.get('maxQuantity');
    }),
    available: Ember.computed('item.quantityAvailable', 'owner.customer.details', function () {
      return this.get('item.quantityAvailable') + (this.get('owner.customer.details.reserved.' + this.get('item.id')) || 0);
    }),
    commitmentError: Ember.computed('specialOrder', 'owner.event.id', 'item.event.id', 'item.quantityAvailable', 'quantity', function () {
      if (this.get('maxQuantity') !== 'undefined' && this.get('maxQuantity') * 1 < this.get('quantity') * 1) {
        if (this.get('item.event.id')) {
          return 'fa-calendar-check-o';
        } else {
          return 'info-circle';
        }
      }
    }),
    maxQuantity: Ember.computed('specialOrder', 'owner.event.id', 'item.event.id', 'item.quantityAvailable', function () {
      if (this.get('owner.event.id') * 1 === 1 || this.get('specialOrder')) {
        return;
      }
      if (!this.get('owner.event.id') || !this.get('item.event.id') || this.get('item.discontinuedAt') || this.get('owner.event.id') !== this.get('item.event.id') || this.get('owner.event.preorderStartsAt') && this.get('owner.event.preorderStartsAt') > new Date() || this.get('owner.event.preorderEndsAt') && this.get('owner.event.preorderEndsAt') < new Date()) {
        if (this.get('item.quantityAvailable') > 0 && this.get('item.salesMultiple') && this.get('item.forceSalesMultiple') && this.get('item.quantityAvailable') < this.get('item.salesMultiple')) {
          return this.get('item.salesMultiple');
        } else {
          return Math.max(this.get('item.quantityAvailable'), 0);
        }
      }
    }),
    discountCategory: Ember.computed.alias('item.discountCategory'),
    categoryValue: Ember.computed('quantity', 'owner.quoteItems.@each.discountCategory', 'owner.quoteItems.@each.extPrice', function () {
      var _this = this;
      var qty = this.get('owner.quoteItems').reduce(function (acc, value) {
        if (value.get('discountCategory') === _this.get('discountCategory')) {
          acc += value.get('basePrice') * value.get('quantity');
        }
        return acc;
      }, 0);
      return Math.round(100 * qty) / 100;
    }),
    categoryQuantity: Ember.computed('quantity', 'owner.quoteItems.@each.discountCategory', 'owner.quoteItems.@each.quantity', function () {
      var _this = this;
      // if (this.get('discountCategory') === 'L') {
      //   if (this.get('owner.customer.company') && this.get('owner.customer.company').indexOf("SMITH'S") > -1 && this.get('owner.customer.company').indexOf("ZURCHERS") > -1) {
      //     return '0%';
      //   } else if (this.get('categoryValue') >= 1000) {
      //     return '10%';
      //   } else if (this.get('categoryValue') >= 500) {
      //     return '5%';
      //   } else {
      //     return '0%';
      //   }
      // }    
      var qty = this.get('owner.quoteItems').reduce(function (acc, value) {
        if (value.get('discountCategory') === _this.get('discountCategory') && value.get('basePrice') > 0 && (!value.get('overridePrice') || value.get('overridePrice') > 0)) {
          acc += value.get('quantity') * 1;
        }
        return acc;
      }, 0);
      return qty;
    }),
    categoryDiscounts: Ember.computed('discountCategory', function () {
      return this.store.peekAll('categoryDiscount').filterBy('discountCode', this.get('discountCategory'));
    }),
    categoryDiscount: Ember.computed('quantity', 'categoryDiscounts', 'categoryQuantity', function () {
      var _this = this;
      return this.get('categoryDiscounts').filter(function (item) {
        return item.get('minQuantity') <= _this.get('categoryQuantity') && item.get('maxQuantity') >= _this.get('categoryQuantity');
      }).get('firstObject');
    }),
    nextDiscount: Ember.computed('quantity', 'categoryDiscount', 'categoryDiscounts', 'categoryQuantity', function () {
      var _this = this;
      return this.get('categoryDiscounts').filter(function (item) {
        return item.get('discountLevel') === (_this.get('categoryDiscount.discountLevel') || 0) + 1;
      }).get('firstObject');
    }),
    nextDiscountQty: Ember.computed('nextDiscount', 'categoryQuantity', function () {
      if (this.get('nextDiscount')) {
        return this.get('nextDiscount.minQuantity'); // - this.get('categoryQuantity');
      }
    }),
    customerCategoryDiscount: Ember.computed('id', 'item.id', 'quantity', 'owner.customer.id', 'owner.customer.categoryDiscounts.@each.discountCode', function () {
      return this.get('owner.customer.categoryDiscounts').filterBy('discountCode', this.get('discountCategory')).get('firstObject');
    }),
    categoryPrice: Ember.computed('inflated', 'quantity', 'categoryQuantity', 'customerCategoryDiscount.discountCode', 'discountCategory', function () {
      this.set('priceMessage', '');
      var _this = this;
      var discount = this.get('customerCategoryDiscount');
      if (discount) {
        var basePrice = _this.get('basePrice');
        if (!this.get('owner.customer.club') && discount.get('discountType') === 'discount') {
          _this.set('priceMessage', 'Item');
          return Math.round(Math.round(basePrice * (100 - discount.get('discountAmount')) * 100) / 100) / 100;
        } else if (discount.get('discountType') === 'cost_plus') {
          _this.set('priceMessage', 'Cost+');
          return Math.round(100 * (_this.get('baseCost') + _this.get('baseCost') * discount.get('discountAmount') / 100)) / 100;
        } else if (!this.get('owner.customer.club') && discount.get('discountType') === 'max_discount') {
          _this.set('priceMessage', 'Max');
          var discountAmount = 0;
          _this.get('categoryDiscounts').forEach(function (discount) {
            discountAmount = Math.max(discountAmount, discount.get('discountAmount'));
          });
          return Math.round(basePrice * (100 - discountAmount)) / 100;
        }
      } else if (!this.get('owner.customer.club') && this.get('categoryDiscount')) {
        this.set('priceMessage', 'Disc ' + this.get('categoryDiscount.discountCode'));
        return this.get('basePrice') - Math.round(this.get('basePrice') * this.get('categoryDiscount.discountAmount')) / 100;
      }
    }),
    hazmat: Ember.computed.alias('item.hazmat'),
    customerItem: Ember.computed('owner.customer.customerItems.@each.itemType', function () {
      var _this = this;
      return this.get('owner.customer.customerItems').filter(function (item) {
        return item.get('itemType') === 'discount' && item.get('item.id') === _this.get('item.id');
      }).get('firstObject');
    }),
    priceMessageObserver: Ember.observer('overridePrice', 'priceMessage', function () {
      if (this.get('overridePrice')) {
        this.set('priceMessage', 'Manual');
      }
    }),
    extPrice: Ember.computed('inflated', 'owner.promo', 'owner.promo.promoItems.@each.sku', 'owner.customer.recent', 'overridePrice', 'item.productGroup.cost', 'item.discounts', 'owner.labeledPricing', 'owner.customer.customerType', 'owner.terms', 'basePrice', 'discountCategory', 'categoryQuantity', 'quantity', 'item.id', 'owner.customer.id', 'categoryPrice', function () {
      return this.get('price') * this.get('quantity');
    }),
    availableExt: Ember.computed('inflated', 'owner.promo', 'owner.promo.promoItems.@each.sku', 'owner.customer.recent', 'overridePrice', 'item.productGroup.cost', 'item.discounts', 'owner.labeledPricing', 'owner.customer.customerType', 'owner.terms', 'basePrice', 'discountCategory', 'categoryQuantity', 'quantity', 'item.id', 'owner.customer.id', 'categoryPrice', function () {
      return this.get('price') * Math.max(0, Math.min(this.get('quantity'), this.get('item.quantityAvailable')));
    }),
    qtyClass: Ember.computed('quantity', function () {
      if (!this.get('quantity') || this.get('quantity') === '') {
        return 'qty warning qi-qty-' + this.get('id');
      }
      return 'qty qi-qty-' + this.get('id');
    }),
    salesMultiple: Ember.computed('quantity', 'owner.customer.customerType', 'item.salesMultiple', 'item.grocerMinimum', function () {
      if (this.get('owner.customer.customerType') === 'grocery' && this.get('item.grocerMinimum')) {
        return Math.min(Math.max(this.get('quantity'), this.get('item.grocerMinimum')), this.get('item.salesMultiple'));
      } else {
        return this.get('item.salesMultiple');
      }
    }),
    multipleError: Ember.computed('item.forceSalesMultiple', 'item.salesMultiple', 'quantity', function () {
      return this.get('item.forceSalesMultiple') && this.get('salesMultiple') * 1 > 1 && this.get('quantity') * 1 % (this.get('salesMultiple') * 1) > 0;
    }),
    promoEligible: Ember.computed('inflated', 'owner.promoSubTotal', 'owner.promo', 'owner.promo.promoItems.@each.sku', 'owner.promo.promoItems.@each.closeout', 'overridePrice', 'owner.special', 'owner.labeledPricing', 'item.closeoutAt', 'item.productGroup.cost', 'item.discounts', 'owner.customer.customerType', 'owner.terms', 'basePrice', 'discountCategory', 'categoryQuantity', 'quantity', function () {
      var item = this.get('item');
      var eligible = false;
      this.get('owner.promo.promoItems').forEach(function (pi) {

        if (pi.get('vendor.id') && pi.get('vendor.id') === item.get('vendor.id') || !pi.get('vendor.id') && (pi.get('item') && pi.get('item') == item || pi.get('skuMatch') === 'any' && pi.get('sku').split(' ').indexOf(item.get('sku')) > -1 || pi.get('skuMatch') === 'equals' && pi.get('sku') === item.get('sku') || pi.get('skuMatch') === 'starts_with' && item.get('sku').slice(0, pi.get('sku.length')) == pi.get('sku') || pi.get('discountCategory') && pi.get('discountCategory.length') > 0 && pi.get('discountCategory') == item.get('discountCategory') || pi.get('closeout') && item.get('closeoutAt') || pi.get('productIds').indexOf(item.get('productType.id') * 1) > -1)) {
          eligible = true;
        }
      });
      return eligible;
    }),

    price: Ember.computed('inflated', 'item.closeoutAt', 'item.sku', 'owner.promo', 'owner.promoSubTotal', 'owner.promo.promoItems.@each.sku', 'owner.customer.recent', 'overridePrice', 'item.productGroup.cost', 'item.discounts', 'owner.labeledPricing', 'owner.customer.customerType', 'owner.terms', 'basePrice', 'discountCategory', 'categoryQuantity', 'quantity', 'item.id', 'owner.customer.id', 'categoryPrice', function () {

      var _this = this;
      this.set('priceMessage', null);
      if (!this.get('item.id') || !this.get('owner.customer.id')) {
        return 0;
      }

      if (this.get('sku') === 'RU-SH') {
        var rushFee = this.get('owner.ownedItems').reduce(function (acc, value) {
          if (value.get('sku') !== 'RU-SH') {
            acc += value.get('extPrice') * 0.1;
          }
          return acc;
        }, 0);
        return Math.max(25, rushFee);
      }

      if (this.get('overridePrice')) {
        return this.get('overridePrice') * 1;
      } else if (this.get('owner.terms') === 'NO CHARGE') {
        return 0;
      } else if (this.get('owner.customer.customerType') === 'no_discounts' || this.get('owner.customer.customerType') === 'affiliate') {
        return this.get('basePrice');
      } else if (this.get('owner.labeledPricing') && this.get('item.productGroup.cost')) {
        return this.get('item.productGroup.cost');
      }

      var item_price = this.get('basePrice');

      if (this.get('item.salePrice') && this.get('item.salePrice') < item_price) {
        item_price = this.get('item.salePrice');
      }

      console.log('Item Price: ' + item_price);

      if (this.get('customerItem')) {
        var customerItem = this.get('customerItem');
        if (customerItem.get('priceType') === 'force') {
          item_price = customerItem.get('price');
        } else {
          item_price = Math.min(item_price, customerItem.get('price'));
        }
      }

      if (this.get('owner.customer.pfaNumber') && this.get('owner.customer.pfaNumber.length') > 2 && this.get('item.pfaPrice')) {
        item_price = Math.min(item_price, this.get('item.pfaPrice'));
      }

      if (this.get('owner.promo') && this.get('owner.promo.promoItems.length') > 0 && (!this.get('owner.promo.minimum') || this.get('owner.baseSubTotal') >= this.get('owner.promo.minimum')) && (!this.get('owner.promo.promoMinimum') || this.get('owner.promoSubTotal') >= this.get('owner.promo.promoMinimum'))) {
        var discount = false;
        var item = this.get('item');

        if (this.get('owner.promo.discountSpecial') && this.get('item.specialPrice')) {
          item_price = Math.min(this.get('item.specialPrice') || item_price, item_price);
          this.set('priceMessage', this.get('owner.promo.code'));
        } else {

          console.log('Promo Subtotal: ' + this.get('owner.promoSubTotal'));

          if (this.get('owner.promo.casePricing')) {
            var _prev = item_price;
            this.get('item.sortedDiscounts').forEach(function (disc) {
              if (['premium', 'grocery', 'partner'].indexOf(_this.get('owner.customer.customerType')) > -1) {
                item_price = Math.min(disc.get('premium'), item_price);
              } else if (_this.get('owner.customer.customerType') === 'preferred') {
                item_price = Math.min(disc.get('preferred'), item_price);
              } else {
                item_price = Math.min(disc.get('wholesale'), item_price);
              }
              if (_this.get('quantity') >= disc.get('quantity') && _prev > item_price) {
                _this.set('priceMessage', 'Buy ' + disc.get('quantity') + '+ @ ' + item_price);
              }
            });
          }

          this.get('owner.promo.promoItems').forEach(function (pi) {
            if ((!pi.get('promoMinimum') || _this.get('owner.promoSubTotal') >= pi.get('promoMinimum')) && (pi.get('vendor.id') && pi.get('vendor.id') === item.get('vendor.id') || !pi.get('vendor.id') && (pi.get('item') && pi.get('item') == item || pi.get('skuMatch') === 'any' && pi.get('sku').split(' ').indexOf(item.get('sku')) > -1 || pi.get('skuMatch') === 'equals' && pi.get('sku') === item.get('sku') || pi.get('skuMatch') === 'starts_with' && item.get('sku').slice(0, pi.get('sku.length')) == pi.get('sku') || pi.get('discountCategory') && pi.get('discountCategory.length') > 0 && pi.get('discountCategory') == item.get('discountCategory') || pi.get('closeout') && item.get('closeoutAt') || pi.get('productIds').indexOf(item.get('productType.id') * 1) > -1))) {

              console.log('Promo: ' + _this.get('sku'));
              console.log('Promo: ' + _this.get('item.vendor.id'));
              console.log('Promo: ' + pi.get('vendor.id'));
              console.log('Promo: ' + (pi.get('vendor.id') == _this.get('item.vendor.id')));

              if (!_this.get('vendorMatched')) {
                discount = Math.max(pi.get('discountPercent') || _this.get('owner.promo.percentDiscount'), discount || 0);
                if (pi.get('discountPercent') === 0) {
                  discount = 0;
                }
              }

              if (pi.get('vendor.id') == _this.get('item.vendor.id')) {
                _this.set('vendorMatched', true);
                discount = pi.get('discountPercent');
              }
            }
          });

          item_price = Math.round(item_price * 100) / 100;

          if (discount) {
            _this.set('priceMessage', _this.get('owner.promo.code') + ' ' + discount + '%');
            var discountAmount = Math.round(Math.round(item_price * discount * 100) / 100) / 100;
            if (this.get('item.sku') === '78-0157XXL' || this.get('item.sku') === "78-0378L" || this.get('item.sku') === '78-0131XL' || this.get('item.sku') === '78-0112L') {
              // console.log(this.get('item.sku'));
              // console.log(`Discount: ${discount}`);
              // console.log(item_price);
              // console.log(discountAmount);            
            }

            item_price = Math.min(item_price, Math.round((item_price - discountAmount) * 100) / 100);
          }
        }
      }

      console.log('Price: ' + this.get('item.sku') + ' ' + item_price);
      var prev = item_price;
      this.get('item.sortedDiscounts').forEach(function (disc) {
        if (_this.get('quantity') >= disc.get('quantity') || _this.get('owner.customer.ivLevel') * 1 > 1 && disc.get('level') < _this.get('owner.customer.ivLevel') * 1) {
          if (['premium', 'grocery', 'partner'].indexOf(_this.get('owner.customer.customerType')) > -1) {
            item_price = Math.min(disc.get('premium'), item_price);
          } else if (_this.get('owner.customer.customerType') === 'preferred') {
            item_price = Math.min(disc.get('preferred'), item_price);
          } else {
            item_price = Math.min(disc.get('wholesale'), item_price);
          }
          if (_this.get('quantity') >= disc.get('quantity') && prev > item_price) {
            _this.set('priceMessage', 'Buy ' + disc.get('quantity') + '+ @ ' + item_price);
          }
        }
      });

      if (['wholsale', 'preferred', 'premium', 'grocery', 'partner'].indexOf(this.get('owner.customer.customerType')) > -1) {
        return Math.round(100 * item_price) / 100;
      }

      if (this.get('categoryPrice')) {
        item_price = Math.min(item_price, this.get('categoryPrice'));
      }

      console.log('Item Price: ' + item_price);

      // if (this.get('discountCategory') === 'L' && (!this.get('owner.customer.company') || this.get('owner.customer.company').indexOf("ZURCHERS") === -1)) {
      //   if (this.get('categoryValue') >= 1000) {
      //     item_price = Math.round(Math.min(item_price,this.get('basePrice') - Math.round(this.get('basePrice') * 10)/100) * 100) / 100;
      //   } else if (this.get('categoryValue') >= 500) {
      //     item_price = Math.min(item_price,this.get('basePrice') - Math.round(this.get('basePrice') * 5)/100);
      //   }
      // }    

      return Math.round(100 * item_price) / 100;
    })

  });
});