define('loftus/models/order-item', ['exports', 'ember-api-actions', 'ember-data'], function (exports, _emberApiActions, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    uvId: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    //quantityPicked: DS.attr('number'),
    quantityPacked: _emberData.default.attr('number'),
    quantityShipped: Ember.computed('quantity', 'quantityBackordered', function () {
      return this.get('quantity') - (this.get('quantityBackordered') || 0) * 1;
    }),
    canInflate: Ember.computed.alias('item.inflate'),
    inflated: _emberData.default.attr('boolean'),
    inflateStick: _emberData.default.attr({ readOnly: true }),
    unit: _emberData.default.attr('string'),
    quantityBackordered: _emberData.default.attr('number'),
    backorderedClass: Ember.computed('quantityBackordered', function () {
      if (this.get('quantityBackordered') > 0) {
        return 'bo qty';
      } else {
        return 'qty';
      }
    }),
    unfulfillable: Ember.computed('item.quantityAvailable', 'quantityBackordered', function () {
      return this.get('oversold') || this.get('quantityBackordered') * 1 > 0;
    }),
    kitOrderItemId: _emberData.default.attr('number', { readOnly: true }),
    inputDisabled: Ember.computed('order.closed', 'kitOrderItemId', function () {
      return this.get('order.closed') || this.get('kitOrderItemId');
    }),
    prop65: Ember.computed.alias('item.prop65'),
    backorderedAt: _emberData.default.attr('date'),
    backorderProcessedAt: _emberData.default.attr('date'),
    backorderProcessedBy: _emberData.default.belongsTo('user'),
    backorderReorderedAt: _emberData.default.attr('date'),
    quantityShort: _emberData.default.attr('number'),
    shortedAt: _emberData.default.attr('date'),
    shortedBy: _emberData.default.belongsTo('user'),
    boqty: 0,
    price: _emberData.default.attr('number'),
    priceMismatch: Ember.computed('price', 'customPrice', function () {
      return this.get('price') !== this.get('customPrice');
    }),
    overridePrice: _emberData.default.attr('number'),
    customerPrice: _emberData.default.attr('number', { readOnly: true }),
    extPrice: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    ecCost: Ember.computed('cost', 'item.averageCost', function () {
      return this.get('cost') || this.get('item.averageCost');
    }),
    ecExtCost: Ember.computed('cost', 'item.averageCost', 'quantity', 'quantityBackordered', function () {
      return this.get('ecCost') * (this.get('quantity') - this.get('quantityBackordered'));
    }),
    extCost: _emberData.default.attr('number'),
    profit: Ember.computed('extCost', 'extPrice', 'quantity', 'quantityBackordered', 'quantityShipped', 'customPrice', 'categoryPrice', function () {
      if (this.get('item.sku') === 'A3-8480') {
        console.log(this.get('ecExtPrice'));
        console.log(this.get('ecExtCost'));
      }

      return this.get('ecExtPrice') - this.get('ecExtCost');
    }),
    netProfit: Ember.computed('profit', 'quantity', 'quantityShipped', 'quantityBackordered', function () {
      return this.get('profit') - (this.get('quantityShipped') * 0.03 + 0.25);
    }),
    discount: _emberData.default.attr('number'),
    originalPrice: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    order: _emberData.default.belongsTo('order'),
    event: _emberData.default.belongsTo('event'),
    eventId: Ember.computed.alias('item.event.id'),
    owner: Ember.computed.alias('order'),
    shipment: _emberData.default.belongsTo('shipment'),
    item: _emberData.default.belongsTo('item'),
    customer: _emberData.default.belongsTo('customer'),
    upsoldUserId: _emberData.default.attr('number'),
    upsoldAt: _emberData.default.attr('date'),
    upsold: Ember.computed('upsoldUserId', function () {
      return !!this.get('upsoldUserId');
    }),
    pickedAt: _emberData.default.attr('date'),
    commentOrderItemId: _emberData.default.attr('number'),
    comments: _emberData.default.attr('string'),
    warehouseNotes: _emberData.default.attr('string'),
    packable: Ember.computed('sku', function () {
      return this.get('item.itemType') !== 'special';
    }),
    packDuration: _emberData.default.attr('number', { readOnly: true }),
    displayComments: Ember.computed('comments', function () {
      if (this.get('comments')) {
        return this.get('comments').split(';').map(function (item) {
          return item.trim();
        });
      }
    }),
    commentClass: Ember.computed('comments', function () {
      return this.get('comments') && this.get('comments.length') > 0 ? 'comment-present' : 'comment-empty';
    }),
    innersRemaining: Ember.computed('item.innerCode', 'item.innerQuantity', 'quantityRemaining', function () {
      if (this.get('item.uom') === 'EA' && this.get('item.innerCode') && this.get('item.innerCode.length') > 0 && this.get('item.innerQuantity') && this.get('quantityRemaining') % this.get('item.innerQuantity') === 0) {
        return this.get('quantityRemaining') / this.get('item.innerQuantity');
      }
    }),
    pickedItems: _emberData.default.hasMany('picked-item', { async: true }),
    orderIssues: _emberData.default.hasMany('order-issue'),
    issuesLength: Ember.computed.alias('orderIssues.length'),
    pickTotes: Ember.computed('pickedItems.@each.pickingTote', function () {
      return this.get('pickedItems').reduce(function (acc, value) {
        acc.push(value.get('pickingTote'));
        return acc;
      }, []);
    }),
    pickingTotes: Ember.computed('pickedItems.@each.pickingTote', function () {
      return this.get('pickedItems').reduce(function (acc, value) {
        console.log(value.belongsTo('pickingTote').value());
        acc.push(value.get('pickingTote.tote.id'));
        return acc;
      }, []);
    }),
    persistedSort: Ember.computed('id', function () {
      return this.get('id') ? 0 : 1;
    }),
    baseCost: Ember.computed('id', 'quantity', 'item.averageCost', 'quantityShipped', function () {
      return Math.max(this.get('item.averageCost'), this.get('item.lastCost'));
    }),
    basePrice: Ember.computed('inflated', 'order.customer.customerType', 'item.wholesale', 'item.preferred', 'item.premium', function () {
      if (this.get('inflated')) {
        console.log('inflated');
        return this.get('item.inflatedPrice');
      } else if (this.get('owner.customer.customerType') === 'wholesale') {
        console.log('wholesale');
        return this.get('item.wholesale');
      } else if (this.get('owner.customer.customerType') === 'preferred') {
        console.log('preferred');
        return this.get('item.preferred');
      } else if (this.get('owner.customer.customerType') === 'premium') {
        console.log('premium');
        return this.get('item.premium');
      } else if (this.get('owner.customer.customerType') === 'partner') {
        console.log('partner');
        return this.get('item.partner') || this.get('item.premium');
      } else if (this.get('owner.customer.customerType') === 'grocery') {
        console.log('grocery');
        return this.get('item.grocery') || this.get('item.premium');
      } else if (this.get('order.customer.customerType') === 'preferred') {
        console.log('is preferred');
        return this.get('item.preferred');
      } else if (this.get('owner.customer.customerType') === 'affiliate') {
        if (this.get('item.affiliatePrice')) {
          return this.get('item.affiliatePrice');
        } else if (this.get('item.preferred')) {
          return Math.round(100 * this.get('item.preferred') / 0.92) / 100;
        } else if (this.get('item.wholesale')) {
          return Math.round(100 * this.get('item.wholesale') / 0.92) / 100;
        }
      } else {
        return this.get('item.wholesale');
      }
    }),
    specialPrice: Ember.computed('order.origin', 'item.specialPrice', function () {
      if (this.get('order.origin') === 'EB-2018') {
        return this.get('item.specialPrice');
      } else if (this.get('order.origin') === 'EB-2018 SHOW') {
        return this.get('item.specialPrice') / 1.05;
      }
    }),
    discountCategory: Ember.computed('item.discountCategory', function () {
      return this.get('item.discountCategory');
    }),
    categoryValue: Ember.computed('quantity', 'order.orderItems.@each.discountCategory', 'order.orderItems.@each.extPrice', function () {
      var _this = this;
      return this.get('order.orderItems').reduce(function (acc, value) {
        if (value.get('discountCategory') === _this.get('discountCategory')) {
          acc += value.get('basePrice') * value.get('quantity');
        }
        return acc;
      }, 0);
    }),
    categoryQuantity: Ember.computed('quantity', 'order.orderItems.@each.discountCategory', 'order.orderItems.@each.quantity', function () {
      var _this = this;
      // if (this.get('discountCategory') === 'L') {
      //   // console.log(this.get('order.customer.company'));
      //   if (this.get('order.customer.company') && this.get('order.customer.company').indexOf("SMITH'S") > -1 && this.get('order.customer.company').indexOf("ZURCHERS") > -1) {
      //   } else if (this.get('categoryValue') >= 1000) {
      //     return '10%';
      //   } else if (this.get('categoryValue') >= 500) {
      //     return '5%';
      //   } else {
      //     return '0%';
      //   }
      // }
      var qty = this.get('order.orderItems').reduce(function (acc, value) {
        if (value.get('discountCategory') === _this.get('discountCategory') && value.get('basePrice') > 0 && (!value.get('overridePrice') || value.get('overridePrice') > 0)) {
          acc += value.get('quantity') * 1;
        }

        return acc;
      }, 0);
      return qty;
    }),
    categoryDiscounts: Ember.computed('discountCategory', function () {
      return this.store.peekAll('categoryDiscount').filterBy('discountCode', this.get('discountCategory'));
    }),
    categoryDiscount: Ember.computed('quantity', 'categoryDiscounts', 'categoryQuantity', function () {
      var _this = this;
      return this.get('categoryDiscounts').filter(function (item) {
        return item.get('minQuantity') <= _this.get('categoryQuantity') && item.get('maxQuantity') >= _this.get('categoryQuantity');
      }).get('firstObject');
    }),
    nextDiscount: Ember.computed('quantity', 'categoryDiscount', 'categoryDiscounts', 'categoryQuantity', function () {
      var _this = this;
      return this.get('categoryDiscounts').filter(function (item) {
        return item.get('discountLevel') === (_this.get('categoryDiscount.discountLevel') || 0) + 1;
      }).get('firstObject');
    }),
    nextDiscountQty: Ember.computed('nextDiscount', 'categoryQuantity', function () {
      if (this.get('nextDiscount')) {
        return this.get('nextDiscount.minQuantity'); // - this.get('categoryQuantity');
        // } else if (this.get('discountCategory') === 'L') {
        //   if (this.get('categoryValue') > 1000) {
        //     return;
        //   }// else if (this.get('categoryValue') > 500)
      }
    }),
    customerCategoryDiscount: Ember.computed('id', 'item.id', 'quantity', 'order.customer.id', 'order.customer.categoryDiscounts.@each.discountCode', function () {
      return this.get('order.customer.categoryDiscounts').filterBy('discountCode', this.get('discountCategory')).get('firstObject');
    }),
    categoryPrice: Ember.computed('inflated', 'quantity', 'categoryQuantity', 'customerCategoryDiscount.discountCode', 'discountCategory', function () {
      this.set('priceMessage', '');
      var _this = this;
      var discount = this.get('customerCategoryDiscount');
      if (!this.get('inflated') && discount) {
        var basePrice = _this.get('basePrice');
        if (discount.get('discountType') === 'discount' && !this.get('owner.customer.club')) {
          _this.set('priceMessage', 'Item');
          return Math.round(Math.round(basePrice * (100 - discount.get('discountAmount')) * 100) / 100) / 100;
        } else if (discount.get('discountType') === 'cost_plus') {
          _this.set('priceMessage', 'Cost+');
          return Math.round(100 * (_this.get('baseCost') + _this.get('baseCost') * discount.get('discountAmount') / 100)) / 100;
        } else if (discount.get('discountType') === 'max_discount' && !this.get('owner.customer.club')) {
          _this.set('priceMessage', 'Max');
          var discountAmount = 0;
          _this.get('categoryDiscounts').forEach(function (discount) {
            discountAmount = Math.max(discountAmount, discount.get('discountAmount'));
          });
          return Math.round(basePrice * (100 - discountAmount)) / 100;
        }
      } else if (this.get('categoryDiscount') && !this.get('owner.customer.club')) {
        this.set('priceMessage', 'Disc ' + this.get('categoryDiscount.discountCode'));
        return this.get('basePrice') - Math.round(this.get('basePrice') * this.get('categoryDiscount.discountAmount')) / 100;
      } else if (this.get('item.salePrice') && this.get('item.salePrice') < this.get('basePrice')) {
        this.set('priceMessage', 'Sale Price');
      }
    }),
    customerItem: Ember.computed('order.customer.customerItems.@each.sku', function () {
      var _this = this;
      return this.get('order.customer.customerItems').filter(function (item) {
        return item.get('itemType') === 'discount' && item.get('item.id') === _this.get('item.id');
      }).get('firstObject');
    }),
    priceMessageObserver: Ember.observer('overridePrice', 'priceMessage', function () {
      if (this.get('overridePrice')) {
        this.set('priceMessage', 'Manual');
      }
    }),
    // threeCasePrice: computed('discountCategory','item.sortedDiscount','order.customer.ivLevel',function(){
    //   let _this = this;
    //   if ([null,'HS','MR'].indexOf(this.get('discountCategory')) > -1 && this.get('order.customer.ivLevel') * 1 > 0) {
    //   let discounts = this.get('item.sortedDiscounts').filter(function(item){
    //     return item.get('level') < _this.get('order.customer.ivLevel') * 1;
    //   });


    //   }

    // }),
    promoEligible: Ember.computed('inflated', 'order.promoSubTotal', 'order.promo', 'order.promo.promoItems.@each.sku', 'overridePrice', 'order.special', 'order.labeledPricing', 'item.productGroup.cost', 'item.discounts', 'order.customer.customerType', 'order.terms', 'basePrice', 'discountCategory', 'categoryQuantity', 'quantity', function () {
      var item = this.get('item');
      var eligible = false;
      this.get('order.promo.promoItems').forEach(function (pi) {
        if (pi.get('vendor.id') && pi.get('vendor.id') === item.get('vendor.id') || !pi.get('vendor.id') && (pi.get('item') && pi.get('item') == item || pi.get('skuMatch') === 'any' && pi.get('sku').split(' ').indexOf(item.get('sku')) > -1 || pi.get('skuMatch') === 'equals' && pi.get('sku') === item.get('sku') || pi.get('skuMatch') === 'starts_with' && item.get('sku').slice(0, pi.get('sku.length')) == pi.get('sku') || pi.get('discountCategory') && pi.get('discountCategory.length') > 0 && pi.get('discountCategory') == item.get('discountCategory') || pi.get('closeout') && item.get('closeoutAt') || pi.get('productIds').indexOf(item.get('productType.id') * 1) > -1)) {
          eligible = true;
        }
      });
      console.log(eligible);
      return eligible;
    }),
    customPrice: Ember.computed('inflated', 'order.promoSubTotal', 'order.promo', 'order.promo.promoItems.@each.sku', 'overridePrice', 'order.special', 'order.labeledPricing', 'item.productGroup.cost', 'item.discounts', 'order.customer.customerType', 'order.terms', 'basePrice', 'discountCategory', 'categoryQuantity', 'quantity', function () {
      var _this = this;

      if (this.get('order.closed')) {
        return this.get('price');
      } else if (typeof this.get('overridePrice') === 'string' && this.get('overridePrice') !== '' || typeof this.get('overridePrice') === 'number') {
        return this.get('overridePrice') * 1;
      } else if (this.get('sku') === 'RU-SH') {
        var rushFee = this.get('owner.ownedItems').reduce(function (acc, value) {
          if (value.get('sku') !== 'RU-SH') {
            acc += Math.round(value.get('extPrice') * 10) / 100;
          }
          return acc;
        }, 0);
        return Math.max(25, rushFee);
      } else if (this.get('order.terms') === 'NO CHARGE') {
        return 0;
      } else if (this.get('quantity') === 0 && this.get('id')) {
        return this.get('price');
      } else if (this.get('order.customer.customerType') === 'no_discounts' || this.get('order.customer.customerType') === 'affiliate') {
        return this.get('basePrice');
      } else if (this.get('order.labeledPricing') && this.get('item.productGroup.cost')) {
        return this.get('item.productGroup.cost');
      }
      var item_price = this.get('basePrice');

      if (this.get('item.salePrice') && this.get('item.salePrice') < item_price) {
        item_price = this.get('item.salePrice');
        this.set('priceMessage', 'Sale Price');
      }

      console.log(item_price);

      if (this.get('customerItem')) {
        var customerItem = this.get('customerItem');
        if (customerItem.get('priceType') === 'force') {
          item_price = customerItem.get('price');
        } else {
          item_price = Math.min(item_price, customerItem.get('price'));
        }
      }

      if (this.get('owner.customer.pfaNumber') && this.get('owner.customer.pfaNumber.length') > 2 && this.get('item.pfaPrice')) {
        item_price = Math.min(item_price, this.get('item.pfaPrice'));
      }

      if (this.get('order.promo') && this.get('order.promo.promoItems.length') > 0 && (!this.get('order.promo.minimum') || this.get('order.baseSubTotal') >= this.get('order.promo.minimum')) && (!this.get('order.promo.promoMinimum') || this.get('order.promoSubTotal') >= this.get('order.promo.promoMinimum'))) {
        var discount = false;
        var item = this.get('item');

        console.log('order promo qualified');

        if (this.get('order.promo.discountSpecial') && this.get('item.specialPrice')) {
          item_price = Math.min(this.get('item.specialPrice') || item_price, item_price);
          this.set('priceMessage', this.get('order.promo.code'));
        } else {

          console.log('Promo Subtotal: ' + this.get('order.promoSubTotal'));

          if (this.get('order.promo.casePricing')) {
            var _prev = item_price;
            this.get('item.sortedDiscounts').forEach(function (disc) {
              if (['premium', 'grocery', 'partner'].indexOf(_this.get('order.customer.customerType')) > -1) {
                item_price = Math.min(disc.get('premium'), item_price);
              } else if (_this.get('order.customer.customerType') === 'preferred') {
                item_price = Math.min(disc.get('preferred'), item_price);
              } else {
                item_price = Math.min(disc.get('wholesale'), item_price);
              }
              if (_this.get('quantity') >= disc.get('quantity') && _prev > item_price) {
                _this.set('priceMessage', 'Buy ' + disc.get('quantity') + '+ @ ' + item_price);
              }
            });
          }

          this.get('order.promo.promoItems').forEach(function (pi) {
            if ((!pi.get('promoMinimum') || _this.get('order.promoSubTotal') >= pi.get('promoMinimum')) && (pi.get('vendor.id') && pi.get('vendor.id') === item.get('vendor.id') || !pi.get('vendor.id') && (pi.get('item') && pi.get('item') == item || pi.get('skuMatch') === 'any' && pi.get('sku').split(' ').indexOf(item.get('sku')) > -1 || pi.get('skuMatch') === 'equals' && pi.get('sku') === item.get('sku') || pi.get('skuMatch') === 'starts_with' && item.get('sku').slice(0, pi.get('sku.length')) == pi.get('sku') || pi.get('discountCategory') && pi.get('discountCategory.length') > 0 && pi.get('discountCategory') == item.get('discountCategory') || pi.get('closeout') && item.get('closeoutAt') || pi.get('productIds').indexOf(item.get('productType.id') * 1) > -1))) {

              console.log('Promo: ' + _this.get('sku'));
              console.log('Promo: ' + _this.get('item.vendor.id'));
              console.log('Promo: ' + pi.get('vendor.id'));
              console.log('Promo: ' + (pi.get('vendor.id') == _this.get('item.vendor.id')));

              if (!_this.get('vendorMatched')) {
                discount = Math.max(pi.get('discountPercent') || _this.get('order.promo.percentDiscount'), discount || 0);
                if (pi.get('discountPercent') === 0) {
                  discount = 0;
                }
              }

              if (pi.get('vendor.id') == _this.get('item.vendor.id')) {
                _this.set('vendorMatched', true);
                discount = pi.get('discountPercent');
              }
            }
          });

          item_price = Math.round(item_price * 100) / 100;

          if (discount) {
            _this.set('priceMessage', _this.get('order.promo.code') + ' ' + discount + '%');
            var discountAmount = Math.round(Math.round(item_price * discount * 100) / 100) / 100;
            if (this.get('item.sku') === '78-0157XXL' || this.get('item.sku') === "78-0378L" || this.get('item.sku') === '78-0131XL' || this.get('item.sku') === '78-0112L') {
              // console.log(this.get('item.sku'));
              // console.log(`Discount: ${discount}`);
              // console.log(item_price);
              // console.log(discountAmount);            
            }

            item_price = Math.min(item_price, Math.round((item_price - discountAmount) * 100) / 100);
          }
        }
      }

      console.log('Price: ' + this.get('item.sku') + ' ' + item_price);
      var prev = item_price;
      this.get('item.sortedDiscounts').forEach(function (disc) {
        if (_this.get('quantity') >= disc.get('quantity') || _this.get('order.customer.ivLevel') * 1 > 1 && disc.get('level') < _this.get('order.customer.ivLevel') * 1) {
          if (['premium', 'grocery', 'partner'].indexOf(_this.get('owner.customer.customerType')) > -1) {
            item_price = Math.min(disc.get('premium'), item_price);
          } else if (_this.get('order.customer.customerType') === 'preferred') {
            item_price = Math.min(disc.get('preferred'), item_price);
          } else {
            item_price = Math.min(disc.get('wholesale'), item_price);
          }
          if (_this.get('quantity') >= disc.get('quantity') && prev > item_price) {
            _this.set('priceMessage', 'Buy ' + disc.get('quantity') + '+ @ ' + item_price);
          }
        }
      });

      if (['wholsale', 'preferred', 'premium', 'grocery', 'partner'].indexOf(this.get('owner.customer.customerType')) > -1) {
        return Math.round(100 * item_price) / 100;
      }

      if (this.get('categoryPrice')) {
        item_price = Math.min(item_price, this.get('categoryPrice'));
      }
      // if (this.get('discountCategory') === 'L' && ((this.get('order.customer.company') && this.get('order.customer.company').indexOf("ZURCHERS") === -1))) {
      //   if (this.get('categoryValue') >= 1000) {
      //     item_price = Math.round(Math.min(item_price,this.get('basePrice') - Math.round(this.get('basePrice') * 10)/100) * 100) / 100;
      //   } else if (this.get('categoryValue') >= 500) {
      //     item_price = Math.min(item_price,this.get('basePrice') - Math.round(this.get('basePrice') * 5)/100);
      //   }
      // }    


      return Math.round(100 * item_price) / 100;
    }),
    unshortedBins: Ember.computed.filterBy('bins', 'lastMissingAt', null),
    bins: Ember.computed('cases', 'advanced', function () {
      if (this.get('cases')) {
        return this.get('item.overstockBins');
      } else if (this.get('podPicking')) {
        console.log('Pod Picking');
        return this.get('item.onlineBins');
      } else if (this.get('advanced')) {
        return this.get('item.fixedBins');
      } else {
        return this.get('item.standardBins');
      }
    }),
    binLocation: Ember.computed.alias('bin.location'),
    bin: Ember.computed('cases', 'item.standardBins.@each.quantity', 'item.standardBins.@each.lastMissingAt', 'item.overstockBins.@each.quantity', 'item.overstockBins.@each.lastMissingAt', function () {

      var _this = this;
      console.log('Recalculate bin for ' + this.get('item.sku'));
      return this.get('bins').sort(function (a, b) {
        if (b.get('lastMissingAt') && !a.get('lastMissingAt')) {
          return -1;
        }
        if (a.get('lastMissingAt') && !b.get('lastMissingAt')) {
          return 1;
        }

        if (!_this.get('cases')) {
          if ((a.get('quantity') || 0) < 2 && (b.get('quantity') || 0) > 1) {
            return -1;
          }
          if ((a.get('quantity') || 0) > 1 && (b.get('quantity') || 0) < 2) {
            return 1;
          }
        }

        if (a.get('pickSection') < b.get('pickSection')) {
          return -1;
        }
        if (a.get('pickSection') > b.get('pickSection')) {
          return 1;
        }
        if (a.get('location.length') < b.get('location.length')) {
          return -1;
        }
        if (a.get('location.length') > b.get('location.length')) {
          return 1;
        }
        if (a.get('location') < b.get('location')) {
          return -1;
        }
        if (a.get('location') > b.get('location')) {
          return 1;
        }

        return 0;
      })[0];
    }),
    overstockBin: Ember.computed('item.overstockBins.@each.quantity', 'item.overstockBins.@each.lastMissingAt', function () {
      var bin = null;
      var bins = this.get('item.overstockBins');
      for (var i = 0; i < bins.length; i++) {
        if (!bins[i].get('lastMissingAt')) {
          if (!bins[i].get('quantity') || bins[i].get('quantity') >= this.get('quantityRemaining') && (!bin || bin.get('quantity') && bins[i].get('quantity') && bin.get('quantity') > bins[i].get('quantity'))) {
            // console.log(bins[i].get('location'));
            bin = bins[i];
          }
        }
      }
      if (!bin) {
        for (var _i = 0; _i < bins.length; _i++) {
          if (bins[_i].get('quantity') > 0 && (!bin || bin.get('quantity') > bins[_i].get('quantity'))) {
            bin = bins[_i];
          }
        }
      }
      if (!bin) {
        for (var _i2 = 0; _i2 < bins.length; _i2++) {
          bin = bins[_i2];
        }
      }
      return bin;
    }),
    pickLocation: _emberData.default.attr('string', { readOnly: true }),
    location: _emberData.default.attr('string', { readOnly: true }),
    tempLocation: Ember.computed.alias('item.tempLocation'),
    inflatesLocation: _emberData.default.attr('string', { readOnly: true }),
    sku: Ember.computed.alias('item.sku'),
    validationClass: Ember.computed('duplicated', function () {
      if (this.get('duplicated')) {
        return 'invalid-duplicate';
      } else {
        return 'valid';
      }
    }),
    duplicated: Ember.computed('order.orderItems.@each.sku', 'sku', function () {
      var _this = this;
      var dupe = this.get('order.orderItems').reduce(function (acc, value) {
        if (value.get('sku') === _this.get('sku') && value.get('id') !== _this.get('id') && !_this.get('item.allowDuplicate')) {
          acc.push(value);
        }
        return acc;
      }, []);

      return dupe.length > 0;
    }),
    canDelete: Ember.computed('order.enteredBy', function () {
      return this.get('order.status') !== 'billed' && this.get('order.status') !== 'paid' && !this.get('approvedAt') && !this.get('kitOrderItemId');
    }),
    unpickedQuantity: Ember.computed('quantity', 'quantityPicked', function () {
      return this.get('quantity') - this.get('quantityPicked') * 1 - this.get('quantityBackordered') * 1;
    }),
    boxed: Ember.computed.mapBy('pickedItems', 'quantityBoxed'),
    quantityBoxed: Ember.computed.sum('boxed'),
    quantityBoxedDirectly: Ember.computed('pickedItems.@each.quantity', function () {
      return this.get('pickedItems').reduce(function (acc, value) {
        if (value.get('box.id')) {
          acc += value.get('quantity');
        }
        return acc;
      }, 0);
    }),
    quantityUnboxed: Ember.computed('quantity', 'quantityBoxed', 'quantityBackordered', function () {
      return this.get('quantityShipped') - this.get('quantityBoxed');
    }),
    isUnboxedRemaining: Ember.computed('quantity', 'quantityBoxed', 'quantityUnboxed', function () {
      if (this.get('item.itemType') === 'special') {
        return false;
      }
      console.log(this.get('quantityUnboxed'));
      return this.get('quantityUnboxed') > 0;
    }),
    isBoxed: Ember.computed('quantity', 'quantityBoxed', 'quantityUnboxed', function () {
      if (this.get('item.itemType') === 'special') {
        return false;
      }
      return this.get('quantityUnboxed') === 0 && this.get('quantityShipped') > 0;
    }),
    isShortRemaining: Ember.computed('quantityShipped', 'quantityBoxed', function () {
      return this.get('packable') && this.get('quantityShipped') != this.get('quantityBoxed');
    }),
    pickError: Ember.computed('quantityShipped', 'quantityPicked', 'quantityBoxed', 'quantityBoxedDirectly', 'order.approvedAt', 'shipment.pickableAt', function () {
      if (this.get('item.itemType') === 'special' || !this.get('order.approvedAt') || !this.get('shipment.pickableAt')) {
        return false;
      }

      return this.get('quantityShipped') !== this.get('quantityPicked') || this.get('quantityBoxedDirectly') > this.get('quantityBoxed');
    }),
    pickIcon: Ember.computed('pickError', function () {
      return this.get('pickError') ? 'exclamation-triangle' : 'info-circle';
    }),
    pickIconSize: Ember.computed('pickError', function () {
      return this.get('pickError') ? 2 : 1;
    }),
    pickSection: Ember.computed.alias('bin.pickSection'),
    // pickSection: DS.attr('number',{readOnly: true}),
    picked: Ember.computed.mapBy('pickedItems', 'quantity'),
    quantityPicked: Ember.computed.sum('picked'),
    isPicked: Ember.computed('quantityPicked', function () {
      return this.get('quantityPicked') > 0;
    }),
    quantityRemaining: Ember.computed('quantity', 'quantityPicked', 'quantityBackordered', function () {
      // console.log('remaining');
      // console.log(this.get('quantity'));
      // console.log(this.get('quantityPicked'));
      // console.log(this.get('quantityBackordered'));
      // console.log(this.get('quantity') - this.get('quantityPicked') - this.get('quantityBackordered'));
      return this.get('quantity') - this.get('quantityPicked') - this.get('quantityBackordered');
    }),
    hazmat: Ember.computed.alias('item.hazmat'),
    casesRemaining: Ember.computed('quantityRemaining', 'item.caseQuantity', function () {
      if (this.get('item.caseQuantity') + 0 === 0) {
        return 0;
      } else if (this.get('item.overstockBins.firstObject.id')) {
        return Math.floor(this.get('quantityRemaining') / this.get('item.caseQuantity'));
      } else {
        return 0;
      }
    }),
    unitsRemaining: Ember.computed('quantityRemaining', 'casesRemaining', 'item.caseQuantity', function () {
      console.log("Cases Remaining");
      console.log(this.get('casesRemaining'));
      console.log(this.get('quantity'));
      console.log(this.get('quantityPicked'));
      console.log(this.get('quantityRemaining'));

      if (!this.get('item.caseQuantity') || this.get('item.caseQuantity') === 0) {
        return this.get('quantityRemaining');
      } else if (this.get('casesRemaining') === 0) {
        return this.get('quantityRemaining');
      } else {
        return this.get('quantityRemaining') % this.get('item.caseQuantity');
      }
    }),
    available: Ember.computed('item.quantityAvailable', 'owner.customer.details', function () {
      return this.get('item.quantityAvailable') + (this.get('owner.customer.details.reserved.' + this.get('item.id')) || 0);
    }),
    oversold: Ember.computed('item.quantityAvailable', 'owner.customer.details', function () {
      return this.get('available') < 0;
    }),
    projectRecordId: _emberData.default.attr('number'),
    unscannable: false,
    scannedItem: '',
    scannedTote: '',
    keypadValue: Ember.computed.alias('unpickedQuantity'),
    ecExtPrice: Ember.computed('inflated', 'quantity', 'quantityBackordered', 'quantityShipped', 'customPrice', 'categoryPrice', function () {
      if (!this.get('id')) {
        this.set('price', this.get('customPrice'));
      }
      return Math.round(this.get('quantityShipped') * this.get('customPrice') * 100) / 100;
    }),
    availableExt: Ember.computed('inflated', 'owner.promo', 'owner.promo.promoItems.@each.sku', 'owner.customer.recent', 'overridePrice', 'item.productGroup.cost', 'item.discounts', 'owner.labeledPricing', 'owner.customer.customerType', 'owner.terms', 'basePrice', 'discountCategory', 'categoryQuantity', 'quantity', 'item.id', 'owner.customer.id', 'categoryPrice', function () {
      return this.get('price') * Math.max(0, Math.min(this.get('quantity'), this.get('quantity') + this.get('item.quantityAvailable')));
    }),
    printBoxTag: (0, _emberApiActions.memberAction)({ path: 'print_box_tag', type: 'put' }),
    printLabels: (0, _emberApiActions.memberAction)({ path: 'print_labels', type: 'put' })

  });
});