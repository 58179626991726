define('loftus/controllers/pods/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    formats: ["Full Pod", "Half Pod", "2x2x1", "2x2x2", "2x2x3", "2x2x4", "2x3x2", "2x3x3", "2x3x4", "2x3x6", "2x4x2", "2x4x3", "2x4x4", "2x4x6", "2x6x6", "2x8x8"],
    materials: ['wood', 'metal'],
    vendorOptions: Ember.computed(function () {
      return this.get('store').findAll('vendor');
    }),
    productTypeOptions: Ember.computed(function () {
      return this.get('store').findAll('product-type');
    }),
    actions: {
      savePod: function savePod() {
        this.get('model').save();
      }
    }

  });
});