define('loftus/models/document', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    scannableType: _emberData.default.attr('string'),
    scannableId: _emberData.default.attr('number'),
    documentType: _emberData.default.attr('string'),
    order: _emberData.default.belongsTo('order'),
    pages: _emberData.default.attr('number'),
    url: _emberData.default.attr('string'),
    link: _emberData.default.attr('string', { readOnly: true }),
    md5: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    fileSize: _emberData.default.attr('number'),
    scannedAt: _emberData.default.attr('date'),
    reviewedBy: _emberData.default.belongsTo('user'),
    reviewedAt: _emberData.default.attr('date'),
    customer: _emberData.default.belongsTo('customer'),
    user: _emberData.default.belongsTo('user', { readOnly: true }),
    amount: _emberData.default.attr('number'),
    reference: _emberData.default.attr('string'),
    completedBy: _emberData.default.belongsTo('user'),
    completedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    projectRecordId: _emberData.default.attr('number'),
    likelyModel: _emberData.default.attr('string'),
    upload: Ember.computed('id', function () {
      return 'file-update-' + this.get('id');
    }),
    review: Ember.computed('url', 'doReview', function () {
      return !this.get('url') || this.get('doReview');
    }),
    bucket: 'https://s3-us-west-1.amazonaws.com/loftus/',
    printInvoices: (0, _emberApiActions.collectionAction)({ path: 'print_invoices', type: 'get' })
  });
});