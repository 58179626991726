define('loftus/models/promo-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    sku: _emberData.default.attr('string'),
    skuMatch: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    promo: _emberData.default.belongsTo('promo'),
    item: _emberData.default.belongsTo('item'),
    vendor: _emberData.default.belongsTo('vendor'),
    discountPercent: _emberData.default.attr('number'),
    discountAmount: _emberData.default.attr('number'),
    discountCategory: _emberData.default.attr('string'),
    promoMinimum: _emberData.default.attr('number'),
    productIds: _emberData.default.attr({ readOnly: true }),
    productType: _emberData.default.belongsTo('product-type'),
    closeout: _emberData.default.attr('boolean'),
    skuExample: Ember.computed('skuMatch', function () {
      if (this.get('skuMatch') === 'any') {
        return 'LF-0001 LF-0002 LF-0003';
      }
      if (this.get('skuMatch') === 'starts_with') {
        return 'LF-';
      }
      if (this.get('skuMatch') === 'equals') {
        return 'LF-0001';
      }
      return 'Select a SKU Match';
    })
  });
});