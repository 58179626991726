define('loftus/controllers/customer/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    tabs: [{ name: 'General', id: 'general' }, { name: 'Account', id: 'account' }, { name: 'Shipping Addresses', id: 'shipping-addresses' }, { name: 'Purchase History', id: 'purchase-history' }, { name: 'Order History', id: 'order-history' }, { name: 'Apply Payments', id: 'apply-payments' }, { name: 'Payment Info', id: 'payment-info' }, { name: 'Related Accounts', id: 'related-accounts' }],
    selectedTab: 'general',
    loadedTabs: ['general'],
    loadedTabObserver: Ember.observer('selectedTab', 'loadedTabs', function () {
      console.log('Tab Changed to ' + this.get('selectedTab'));
      switch (this.get('selectedTab')) {
        case 'purchase-history':
          this.set('loadPurchaseHistory', true);
          break;
        case 'order-history':
          if (!$('.recent-orders th:first').hasClass('init-load')) {
            $('.recent-orders th:first').click();
            $('.recent-orders th:first').addClass('init-load');
          }
          break;
        case 'apply-payments':
          if (!$('.billed-orders th:first').hasClass('init-load')) {
            $('.billed-orders th:first').click();
            $('.billed-orders th:first').addClass('init-load');
          }
          break;
      }
    }),
    dtDesc: [[0, 'desc']],
    actions: {
      save: function save() {
        this.model.save();
      },
      focusTab: function focusTab(selectedTab) {
        this.set('selectedTab', selectedTab.id);
        var loaded = this.get('loadedTabs');
        loaded.push(selectedTab.id);
        this.set('loadedTabs', loaded);
      },
      destroyAddress: function destroyAddress(address) {
        address.set('customer', null);
        address.destroyRecord();
        var table = $('.ship-to-' + address.id).parent('table').dataTable();
        $('.ship-to-' + address.id).remove();
        table.fnDraw();
      },
      updateCreditCard: function updateCreditCard() {
        this.model.set('updateCreditCard', !this.model.get('updateCreditCard'));
      },
      enterOrderPaymentCardInfo: function enterOrderPaymentCardInfo() {
        this.model.set('enterOrderPaymentCardInfo', !this.model.get('enterOrderPaymentCardInfo'));
      },
      chargeSelectedOrders: function chargeSelectedOrders(token) {
        var _this2 = this;

        var paymentToken = token || this.get('model.paymentToken');

        this.model.chargeOrders({ orders: this.get('model.paymentOrders'), total: this.get('model.paymentTotal'), paymenttoken: paymentToken }).then(function (response) {
          if (response.error) {
            _this2.set('model.paymentResponse', response.error);
          } else {
            _this2.store.pushPayload(response);
            _this2.set('model.paymentOrders', []);
            _this2.set('model.paymentTotal', 0);
            _this2.set('model.paymentResponse', 'Payment Successful');
          }
        });
      },
      prepayFreight: function prepayFreight(percent) {
        if (this.get('model.discountFreightPercent') === percent) {
          this.set('model.discountFreightPercent', 0);
        } else {
          this.set('model.discountFreightPercent', percent);
        }
      },
      setCustomerType: function setCustomerType(value) {
        this.set('model.customerType', value);
      },
      toggle: function toggle(field) {
        console.log('toggle ' + field);
        console.log(this.get('model.' + field));
        console.log(!this.get('model.' + field));
        this.set('model.' + field, !this.get('model.' + field));
      },
      setDiscount: function setDiscount(type) {
        console.log('discount ' + type);
        this.set('model.discountType', type + '_discount');
      },
      unregisterToken: function unregisterToken() {
        var _this = this;
        this.model.unregisterToken().then(function (response) {
          _this.store.pushPayload(response);
        });
      },
      setPayment: function setPayment(order) {
        order.payment = !order.payment;
        if (!(this.get('model.paymentTotal') >= 0)) {
          this.set('model.paymentTotal', 0);
        }
        if (order.payment) {
          this.set('model.paymentTotal', Math.round(100 * (this.get('model.paymentTotal') * 1 + order.total * 1)) / 100);
          var orderIds = this.get('model.paymentOrders');
          orderIds.push(order.id);
          this.set('model.paymentOrders', orderIds);
        } else {
          this.set('model.paymentTotal', Math.round(100 * (this.get('model.paymentTotal') * 1 - order.total * 1)) / 100);
          var _orderIds = this.get('model.paymentOrders');
          var index = _orderIds.indexOf(order.id);
          if (index > -1) {
            _orderIds.splice(index, 1);
            this.set('model.paymentOrders', _orderIds);
          }
        }

        //paymentOrders: [],
        //paymentTotal: 0,
      }

    },
    keyboardShortcuts: {
      'ctrl+s': function ctrlS() {
        console.log('Save!');
        //this.controllerFor('application').send('toggleOrderSearch');
        return false;
      }
    }

  });
});