define('loftus/models/page', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    handle: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    templateSuffix: _emberData.default.attr('string'),
    depth: _emberData.default.attr('number', { readOnly: true }),
    childrenCount: _emberData.default.attr('number', { readOnly: true }),
    bodyHtml: _emberData.default.attr('string'),
    imageUrl: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    parent: _emberData.default.belongsTo('page', { inverse: 'pages' }),
    promo: _emberData.default.belongsTo('promo'),
    risque: _emberData.default.attr('boolean'),
    position: _emberData.default.attr('number'),
    collections: _emberData.default.hasMany('collection'),
    active: _emberData.default.attr('boolean'),
    collapseCollections: _emberData.default.attr('boolean'),
    startAt: _emberData.default.attr('date'),
    endAt: _emberData.default.attr('date'),
    pageSort: ['position'],
    sortedPages: Ember.computed.sort('pages', 'pageSort'),
    sortedCollections: Ember.computed.sort('collections', 'pageSort'),
    pageBlocks: _emberData.default.hasMany('page-block'),
    blocks: Ember.computed.sort('pageBlocks', 'pageSort'),
    pages: _emberData.default.hasMany('page', { inverse: 'parent' }),
    chain: _emberData.default.belongsTo('chain'),
    event: _emberData.default.belongsTo('event'),
    copyTo: _emberData.default.belongsTo('page'),
    pbs: Ember.computed('pageBlocks', function () {
      this.get('pageBlocks').forEach(function (pb) {
        console.log(pb.get('param'));
      });
      return this.get('pageBlocks');
    }),
    tree: Ember.computed('parent.tree', 'title', function () {
      var tree = [this.get('parent.tree'), this.get('title')];
      var temp = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = tree[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var i = _step.value;

          i && temp.push(i);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return temp.join(' > ');
    })
  });
});