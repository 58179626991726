define('loftus/router', ['exports', 'loftus/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('customers');
    this.route('customer', function () {
      this.route('new', { path: '/' });
      this.route('edit', { path: '/:customer_id' });
    });
    this.route('orders', function () {
      this.route('overview');
      this.route('packed');
    });
    this.route('order', function () {
      this.route('queue', { path: '/queue' });
      this.route('edit', { path: '/:order_id' });
      this.route('pick', { path: '/:order_id/pick' });
      this.route('process', { path: '/process' });

      this.route('new');
      this.route('new', { path: '/:customer_id/new' });
      this.route('entries');
    });
    this.route('restock-items');
    this.route('items', function () {
      this.route('index');
      this.route('measure');
      this.route('overstock');
    });
    this.route('item', function () {
      this.route('edit', { path: '/:item_id' });
      this.route('report');
      this.route('labels');
      this.route('bins');
    });
    this.route('shipments', function () {
      this.route('pack');
      this.route('ship');
    });
    this.route('order-items');
    this.route('login');
    this.route('purchase-orders', function () {});
    this.route('purchase-order', function () {
      this.route('edit', { path: '/:purchase_order_id' });
      this.route('receive', { path: '/:purchase_order_id/receive' });
      this.route('receiving', { path: '/receiving' });
      this.route('multi-po');
    });
    this.route('purchase-order-items');
    this.route('vendors', function () {
      this.route('edit', { path: '/:vendor_id' });
    });
    this.route('picked-items');
    this.route('received-items');
    this.route('receiving-totes');
    this.route('receiving-tote', function () {
      this.route('edit', { path: '/:receiving_tote_id' });
      this.route('stock', { path: '/:receiving_tote_id/stock' });
    });

    this.route('totes', function () {
      this.route('scan', { path: '/scan' });
      this.route('stock', { path: '/:receiving_tote_id/stock' });
      this.route('manage');
    });
    this.route('picking-totes');
    this.route('picking-tote', function () {
      this.route('edit', { path: '/:picking_tote_id' });
      this.route('process', { path: '/:picking_tote_id/process' });
    });

    this.route('boxes');
    this.route('box', function () {
      this.route('edit', { path: '/:box_id' });
    });
    this.route('box-contents');
    this.route('picking-bins');
    this.route('picking-issues');
    this.route('receiving-issues');
    this.route('stocking-issues');
    this.route('shelves');
    this.route('bins');
    this.route('projects');
    this.route('project', function () {
      this.route('edit', { path: '/:project_id' });
      this.route('work', { path: '/work' });
      this.route('users');
      this.route('do', { path: '/work/:project_batch_id' });
    });
    this.route('project-batches', function () {});
    this.route('project-batch', function () {
      this.route('edit', { path: '/:project_batch_id' });
    });
    this.route('project-records');
    this.route('users');
    this.route('user', function () {
      this.route('edit', { path: '/:user_id' });
      this.route('profile', { path: '/profile' });
      this.route('reports', { path: '/reports' });

      this.route('oauth');
    });
    this.route('import');
    this.route('roles');
    this.route('role', function () {
      this.route('edit', { path: '/:role_id' });
    });
    this.route('permissions');
    this.route('assigned-permissions');
    this.route('user-roles');
    this.route('issues');
    this.route('cartons');
    this.route('purchase-order-issues');
    this.route('order-payments');
    this.route('quantity-discounts');
    this.route('project-users');
    this.route('category-discounts');
    this.route('customer-category-discounts');
    this.route('customer-items');
    this.route('customer-imprints');
    this.route('quotes');
    this.route('quote', function () {
      this.route('new', { path: '/' });
      this.route('edit', { path: '/:quote_id' });
    });
    this.route('quote-items');
    this.route('related-items');
    this.route('categories');
    this.route('catalog-items');
    this.route('payment-tokens');
    this.route('order-events');
    this.route('notifications');
    this.route('order-returns');
    this.route('category-items');
    this.route('product-groups');
    this.route('shipping-services');
    this.route('catalogs');
    this.route('catalog-listings');
    this.route('notes');
    this.route('contacts', function () {
      this.route('index', { path: '/index' });
      this.route('text', { path: '/:contact_id' });
    });
    this.route('leads');
    this.route('scoreboard');
    this.route('picking');
    this.route('calls');
    this.route('promos', function () {
      this.route('list');
      this.route('edit', { path: '/:promo_id' });
    });
    this.route('promo-items');
    this.route('chains', function () {
      this.route('edit', { path: '/:chain_id' });
      this.route('manage');
    });
    this.route('pages', function () {
      this.route('index', { path: '/index' });
      this.route('edit', { path: '/:page_id' });
    });
    this.route('collections', function () {
      this.route('index', { path: '/index' });
      this.route('edit', { path: '/:collection_id' });
    });
    this.route('collection-items');
    this.route('sub-journals');
    this.route('freight-shipments', function () {
      this.route('edit', { path: '/:freight_shipment_id' });
      this.route('receive', { path: '/:freight_shipment_id/receive' });
      this.route('index', { path: '/index' });
    });
    this.route('freight-cartons');
    this.route('freight-items');
    this.route('freight-scans');
    this.route('page-blocks');
    this.route('web-users');
    this.route('time-clocks');
    this.route('upcs');
    this.route('item-assets');
    this.route('reports', function () {
      this.route('performance');
      this.route('sales');
    });

    this.route('purchase-orers', function () {});
    this.route('item-adjustments');
    this.route('sales');
    this.route('sales-reps');
    this.route('stations');
    this.route('item-alerts');
    this.route('work-orders', function () {
      this.route('index', { path: '/index' });
      this.route('edit', { path: '/:work_order_id' });
    });
    this.route('work-order-items');
    this.route('warehouse');

    this.route('shipment', function () {
      this.route('process', { path: '/:shipment_id/process' });
    });
    this.route('status-report');
    this.route('product-types');
    this.route('seasons');

    this.route('chain', function () {});
    this.route('articles', function () {
      this.route('manage');
      this.route('edit', { path: '/:article_id' });
    });

    this.route('properties', function () {
      this.route('manage');
    });
    this.route('faqs');
    this.route('events');
    this.route('receiving-carts');
    this.route('receiving-bins');
    this.route('price-changes');
    this.route('cart-items');
    this.route('kits');
    this.route('printers');
    this.route('tasks');
    this.route('bots', function () {
      this.route('index', { path: '/index' });
      this.route('edit', { path: '/:bot_id' });
    });
    this.route('command', function () {
      this.route('index', { path: '/index' });
      this.route('zone', { path: '/:zone_id' });
    });
    this.route('pods', function () {
      this.route('manage');
      this.route('edit', { path: '/:pod_id' });
    });
    this.route('zones', function () {
      this.route('index', { path: '/index' });
      this.route('manage', { path: '/:zone_id' });
    });
    this.route('404', { path: '/*path' });
    this.route('purchasing');
    this.route('pod-picking');
    this.route('pod-light');
    this.route('sales-scoreboard');
    this.route('routes', function () {
      this.route('edit', { path: '/:route_id' });
    });
    this.route('warehouse-scoreboard');
    this.route('price-codes');
    this.route('emails');
    this.route('pallets');
    this.route(' ');
    this.route('surveys');
    this.route('settings');
    this.route('bills');
  });

  exports.default = Router;
});