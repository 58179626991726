define('loftus/models/pod', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    weight: _emberData.default.attr('number'),
    material: _emberData.default.attr('string'),
    height: _emberData.default.attr('number'),
    angle: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    bot: _emberData.default.belongsTo('bot', { inverse: 'pod' }),
    points: _emberData.default.hasMany('point'),
    clear: _emberData.default.attr('boolean'),
    tier: _emberData.default.attr('number'),
    zone: _emberData.default.belongsTo('zone', { readOnly: true }),
    format: _emberData.default.attr('string'),
    scannedPoint: _emberData.default.attr('string'),
    toPoint: _emberData.default.attr('string'),
    point: _emberData.default.belongsTo('point'),
    px: Ember.computed.alias('zone.px'),
    scale: Ember.computed.alias('zone.scale'),
    pickAt: _emberData.default.attr('date'),
    receiveAt: _emberData.default.attr('date'),
    vendor: _emberData.default.belongsTo('vendor'),
    productType: _emberData.default.belongsTo('product-type'),
    xx: Ember.computed.alias('point.xx'),
    yy: Ember.computed.alias('point.yy'),
    adjust: _emberData.default.attr('number'),
    size: Ember.computed('scale', function () {
      return this.get('scale') * 96;
    }),
    svgX: Ember.computed('point.x', 'angle', function () {
      // console.log(this.get('point'));
      // console.log(this.get('point.x'));
      return this.get('point.x') * this.get('px') + this.get('scale') * 16;
    }),
    svgY: Ember.computed('point.y', 'angle', function () {
      // console.log(this.get('point'));
      // console.log(this.get('point.y'));
      return this.get('point.y') * this.get('px') + this.get('scale') * 64;
    }),
    numberSize: Ember.computed('scale', function () {
      if (this.get('number') === '1005') {
        console.log(this.get('scale') * 0.6);
        return this.get('scale') * 0.6;
      }
    }),
    color: Ember.computed('status', function () {
      if (this.get('status') === 'picking') {
        return '#ff800';
      } else if (this.get('status') === 'blocking') {
        return '#ffff00';
      } else if (this.get('status') === 'active') {
        return '#ff00ff';
      } else if (this.get('status') === 'will_call') {
        return '#0000ff';
      } else if (this.get('status') === 'must_ship') {
        return '#00ff00';
      } else if (this.get('status') === 'standard_picking') {
        return '#ff0000';
      } else if (this.get('status') === 'receiving') {
        return '#a63d76';
      } else if (this.get('receiveAt')) {
        return '#ff088d';
      } else if (this.get('pickAt')) {
        return '#149114';
      } else {
        return '#000000';
      }
    }),
    line: Ember.computed('point.x', 'point.y', 'angle', function () {
      if (this.get('angle') === 0) {
        return this.get('svgX') - this.get('scale') * 44 + ',' + (this.get('svgY') + this.get('scale') * 4) + ' ' + (this.get('svgX') + this.get('scale') * 52) + ',' + (this.get('svgY') + this.get('scale') * 4);
      } else if (this.get('angle') === 90) {
        return this.get('svgX') + this.get('scale') * 4 + ',' + (this.get('svgY') - this.get('scale') * 44) + ' ' + (this.get('svgX') + this.get('scale') * 4) + ',' + (this.get('svgY') + this.get('scale') * 52);
      } else if (this.get('angle') === 180) {
        return this.get('svgX') - this.get('scale') * 44 + ',' + (this.get('svgY') + this.get('scale') * 4) + ' ' + (this.get('svgX') + this.get('scale') * 52) + ',' + (this.get('svgY') + this.get('scale') * 4);
      } else if (this.get('angle') === 270) {
        return this.get('svgX') + this.get('scale') * 4 + ',' + (this.get('svgY') - this.get('scale') * 44) + ' ' + (this.get('svgX') + this.get('scale') * 4) + ',' + (this.get('svgY') + this.get('scale') * 52);
      }
    }),
    sideSize: Ember.computed('scale', function () {
      return this.get('scale') * 1.2;
    }),
    sides: Ember.computed('point.x', 'point.y', 'angle', function () {
      if (this.get('angle') === 0) {
        return [{ text: '1', x: this.get('xx') + 80 * this.get('scale'), y: this.get('yy') + this.get('scale') * 20 }, { text: '2', x: this.get('xx') + 6 * this.get('scale'), y: this.get('yy') + this.get('scale') * 86 }];
      } else if (this.get('angle') === 90) {
        return [{ text: '2', x: this.get('xx') + this.get('scale') * 6, y: this.get('yy') + this.get('scale') * 86 }, { text: '1', x: this.get('xx') + this.get('scale') * 80, y: this.get('yy') + this.get('scale') * 20 }];
      } else if (this.get('angle') === 180) {
        return [{ text: '2', x: this.get('xx') + 80 * this.get('scale'), y: this.get('yy') + this.get('scale') * 20 }, { text: '1', x: this.get('xx') + 6 * this.get('scale'), y: this.get('yy') + this.get('scale') * 86 }];
      } else if (this.get('angle') === 270) {
        return [{ text: '1', x: this.get('xx') + 6 * this.get('scale'), y: this.get('yy') + this.get('scale') * 86 }, { text: '2', x: this.get('xx') + 80 * this.get('scale'), y: this.get('yy') + this.get('scale') * 20 }];
      }
    }),
    x: Ember.computed('id', 'point.zone.id', 'point.x', function () {
      if (this.get('point.zone.id') < 6) {
        var x = this.get('scale') * 16;
        return x + this.get('point.x') * this.get('px') - this.get('scale') * 44;
      }
    }),
    y: Ember.computed('id', 'point.zone.id', 'point.y', function () {
      if (this.get('point.zone.id') < 6) {
        var y = this.get('scale') * 64;
        return y + this.get('point.y') * this.get('px') - this.get('scale') * 44;
      }
    }),
    textX: Ember.computed('id', 'point.zone.id', 'point.x', function () {
      if (this.get('point.zone.id') < 6) {
        var x = this.get('scale') * 76;
        return x + this.get('point.x') * this.get('px') - this.get('scale') * 40;
      }
    }),
    textY: Ember.computed('id', 'point.zone.id', 'point.y', function () {
      if (this.get('point.zone.id') < 6) {
        var y = this.get('scale') * 146;
        return y + this.get('point.y') * this.get('px') - this.get('scale') * 32;
      }
    }),
    tierX: Ember.computed('id', 'point.zone.id', 'point.x', function () {
      if (this.get('point.zone.id') < 6) {
        var x = this.get('scale') * 76;
        return x * 0.25 + this.get('point.x') * this.get('px') - this.get('scale') * 40;
      }
    }),
    tierY: Ember.computed('id', 'point.zone.id', 'point.y', function () {
      if (this.get('point.zone.id') < 6) {
        var y = this.get('scale') * 146;
        return y + this.get('point.y') * this.get('px') - this.get('scale') * 32 - y * 0.55;
      }
    }),
    noBins: (0, _emberApiActions.collectionAction)({ path: 'no_bins', type: 'put' }),
    wrongSide: (0, _emberApiActions.collectionAction)({ path: 'wrong_side', type: 'put' }),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' })

  });
});