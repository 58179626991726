define('loftus/models/item', ['exports', 'ember-data', 'loftus/models/recordable', 'ember-api-actions'], function (exports, _emberData, _recordable, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _recordable.default.extend({
    sku: _emberData.default.attr('string'),
    upc: _emberData.default.attr('string'),
    underReview: _emberData.default.attr('boolean'),
    specialOrder: _emberData.default.attr('boolean'),
    caseCode: _emberData.default.attr('string'),
    innerCode: _emberData.default.attr('string'),
    mfCode: _emberData.default.attr('string'),
    bbCode: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    reorderNumber: _emberData.default.attr('string'),
    uom: _emberData.default.attr('string'),
    unitsPicked: _emberData.default.attr('number', { readOnly: true }),
    spokenUnit: _emberData.default.attr('string', { readOnly: true }),
    uomDisplay: Ember.computed('uom', function () {
      return ('' + this.get('uom')).split('~')[0];
    }),
    value: Ember.computed('onHand', 'averageCost', function () {
      return Math.ceil(this.get('onHand') * this.get('averageCost') * 100) / 100;
    }),
    onHand: _emberData.default.attr('number', { readOnly: true }),
    reserved: _emberData.default.attr('number'),
    averageCost: _emberData.default.attr('number'),
    orderQuantityLimit: _emberData.default.attr('number'),
    quantityLimitDays: _emberData.default.attr('number'),
    location: _emberData.default.attr('string'),
    binQty: _emberData.default.attr('number'),
    stockByCase: _emberData.default.attr('boolean'),
    pickSection: _emberData.default.attr('number', { readOnly: true }),
    stockSection: _emberData.default.attr('number', { readOnly: true }),
    weight: _emberData.default.attr('number'),
    reorderPoint: _emberData.default.attr('number'),
    restockLevel: _emberData.default.attr('number'),
    newReorderPoint: _emberData.default.attr('number'),
    newRestockLevel: _emberData.default.attr('number'),
    projection: _emberData.default.attr({ readOnly: true }),
    lastCost: _emberData.default.attr('number'),
    lastOrderedOn: _emberData.default.attr('date'),
    embargoEndsAt: _emberData.default.attr('date'),
    lastOrderedQuantity: _emberData.default.attr('number'),
    boxDirectly: _emberData.default.attr('boolean'),
    restricted: _emberData.default.attr('boolean'),
    ecommerceRestricted: _emberData.default.attr('boolean'),
    quantityCommitted: _emberData.default.attr('number', { readOnly: true }),
    specialCommitted: _emberData.default.attr('number', { readOnly: true }),
    backorderCommitted: _emberData.default.attr('number', { readOnly: true }),
    palletize: _emberData.default.attr({ readOnly: true }),
    podify: _emberData.default.attr({ readOnly: true }),
    freight: _emberData.default.attr({ readOnly: true }),
    comments: _emberData.default.attr('string'),
    vendorPriceCode: _emberData.default.attr('string'),
    priceCode: _emberData.default.belongsTo('price-code'),
    catalogLayout: _emberData.default.attr('string'),
    commentDisplay: Ember.computed('comments', function () {
      return ('' + this.get('comments')).replace(/~/g, "\n");
    }),
    titleSku: Ember.computed('name', 'sku', function () {
      return this.get('title') + ' ' + this.get('sku');
    }),
    lastReceivedAt: _emberData.default.attr('date'),
    lastReceivedQuantity: _emberData.default.attr('number'),
    firstReceivedAt: _emberData.default.attr('date'),
    vendor: _emberData.default.belongsTo('vendor'),
    season: _emberData.default.belongsTo('season'),
    event: _emberData.default.belongsTo('event'),
    productType: _emberData.default.belongsTo('product-type'),
    vendorName: _emberData.default.attr('string'),
    vendorStock: _emberData.default.attr('number'),
    currencyType: _emberData.default.attr('string'),
    htsCode: _emberData.default.attr('string'),
    dutyPercent: _emberData.default.attr('string'),
    duty: _emberData.default.attr('number'),
    prop65: _emberData.default.attr('string'),
    itemType: _emberData.default.attr('string'),
    lastReceivedBy: _emberData.default.attr('string'),
    lastReceivedUnitType: _emberData.default.attr('string'),
    lastReceivedCostPerUnit: _emberData.default.attr('number'),
    productGroup: _emberData.default.belongsTo('product-group'),
    discountCategory: _emberData.default.attr('string'),
    msrp: _emberData.default.attr('number'),
    categoryItems: _emberData.default.hasMany('category-item'),
    priceChanges: _emberData.default.hasMany('price-change'),
    itemPropertyValues: _emberData.default.hasMany('item-property-value'),
    assemblies: _emberData.default.hasMany('assembly', { inverse: 'item' }),
    assembly: _emberData.default.hasMany('assembly', { inverse: 'partItem' }),
    assemblyCost: Ember.computed('itemType', 'assemblies.@each.cost', function () {
      if (this.get('itemType') === 'assembly') {
        return this.get('assemblies').reduce(function (acc, value) {
          acc += value.get('cost');
          return acc;
        }, 0);
      }
    }),
    assortments: _emberData.default.hasMany('assortment', { inverse: 'item' }),
    assortment: _emberData.default.belongsTo('assortment', { inverse: 'assortedItem' }),
    itemAdjustments: _emberData.default.hasMany('item-adjustment'),
    receivedEvents: _emberData.default.hasMany('received-event'),
    purchaseOrderItems: _emberData.default.hasMany('purchase-order-item'),
    fullImage: _emberData.default.attr('boolean'),
    webItem: _emberData.default.attr('boolean'),
    webStatus: _emberData.default.attr('string'),
    risque: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    catalogDescription: _emberData.default.attr('string'),
    caseDescription: _emberData.default.attr('string'),
    weightDescription: _emberData.default.attr('string'),
    itemDims: Ember.computed('length', 'width', 'height', 'weight', function () {
      if (this.get('length') && this.get('width') && this.get('height')) {
        return this.get('length') + '" x ' + this.get('width') + '" x ' + this.get('height') + '" ' + this.get('weight') + ' lb';
      }
    }),
    messageStartsAt: _emberData.default.attr('date'),
    messageEndsAt: _emberData.default.attr('date'),
    flashMessage: _emberData.default.attr('string'),
    fobPrice: _emberData.default.attr('number'),
    affiliatePrice: _emberData.default.attr('number'),
    inflatedPrice: _emberData.default.attr('number'),
    grocery: _emberData.default.attr('number'),
    partner: _emberData.default.attr('number'),
    preferred: _emberData.default.attr('number'),
    premium: _emberData.default.attr('number'),
    wholesale: _emberData.default.attr('number'),
    inflate: _emberData.default.attr('boolean'),
    prePick: _emberData.default.attr('boolean'),
    specialPrice: _emberData.default.attr('number'),
    salePrice: _emberData.default.attr('number'),
    mapPrice: _emberData.default.attr('number'),
    pfaPrice: _emberData.default.attr('number'),
    originCountry: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    expectedOn: _emberData.default.attr('date'),
    shelf: _emberData.default.belongsTo('shelf'),
    caseCubiscannedAt: _emberData.default.attr('date'),
    caseInners: _emberData.default.attr('number'),
    caseLength: _emberData.default.attr('number'),
    caseWidth: _emberData.default.attr('number'),
    caseHeight: _emberData.default.attr('number'),
    caseWeight: _emberData.default.attr('number'),
    caseVolume: Ember.computed('caseLength', 'caseWidth', 'caseHeight', function () {
      return 1 * this.get('caseLength') * this.get('caseWidth') * this.get('caseHeight');
    }),
    caseQuantity: _emberData.default.attr('number'),
    caseDims: Ember.computed('caseLength', 'caseWidth', 'caseHeight', 'caseWeight', function () {
      if (this.get('caseLength') && this.get('caseWidth') && this.get('caseHeight')) {
        return this.get('caseLength') + '" x ' + this.get('caseWidth') + '" x ' + this.get('caseHeight') + '" ' + this.get('caseWeight') + ' lb';
      }
    }),
    innerCubiscannedAt: _emberData.default.attr('date'),
    innerLength: _emberData.default.attr('number'),
    innerWidth: _emberData.default.attr('number'),
    innerHeight: _emberData.default.attr('number'),
    innerWeight: _emberData.default.attr('number'),
    innerVolume: Ember.computed('innerLength', 'innerWidth', 'innerHeight', function () {
      return 1 * this.get('innerLength') * this.get('innerWidth') * this.get('innerHeight');
    }),
    innerQuantity: _emberData.default.attr('number'),
    innerDims: Ember.computed('innerLength', 'innerWidth', 'innerHeight', 'innerWeight', function () {
      if (this.get('innerLength') && this.get('innerWidth') && this.get('innerHeight')) {
        return this.get('innerLength') + '" x ' + this.get('innerWidth') + '" x ' + this.get('innerHeight') + '" ' + this.get('innerWeight') + ' lb';
      }
    }),
    unitQuantity: _emberData.default.attr('number'),
    salesMultiple: _emberData.default.attr('number'),
    forceSalesMultiple: _emberData.default.attr('boolean'),
    grocerMinimum: _emberData.default.attr('number'),
    moq: _emberData.default.attr('number'),
    group: _emberData.default.attr('string'),
    daysToStock: _emberData.default.attr('number'),
    extraDaysToStock: _emberData.default.attr('number'),
    perUnit: _emberData.default.attr('number'),
    receivingMultiple: _emberData.default.attr('number'),
    purchasingMultiple: _emberData.default.attr('number'),
    purchasingDivisor: _emberData.default.attr('number'),
    purchasingUnit: _emberData.default.attr('string'),
    packageCount: _emberData.default.attr('number'),
    packaging: _emberData.default.attr('string'),
    unitCubiscannedAt: _emberData.default.attr('date'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    lastMissingAt: _emberData.default.attr('date'),
    lastCountedAt: _emberData.default.attr('date'),
    requireCountAt: _emberData.default.attr('date'),
    lastSoldAt: _emberData.default.attr('date'),
    confirmMissingValidated: _emberData.default.attr('boolean'),
    backorderedAt: _emberData.default.attr('date'),
    lastCountedQuantity: _emberData.default.attr('number'),
    physicalCount: _emberData.default.attr('boolean'),
    adjustDown: _emberData.default.attr('boolean'),
    lastStockedAt: _emberData.default.attr('date'),
    stockCases: _emberData.default.attr('boolean'),
    discontinuedAt: _emberData.default.attr('date'),
    closeoutAt: _emberData.default.attr('date'),
    seasonStockedOutAt: _emberData.default.attr('date'),
    allowDuplicate: _emberData.default.attr('boolean'),
    trainingRequired: _emberData.default.attr('boolean'),
    hazmat: _emberData.default.attr('string'),
    outForSeason: Ember.computed('seasonStockedOutAt', function () {
      return !!this.get('seasonStockedOutAt');
    }),
    zone: _emberData.default.attr('number', { readOnly: true }),
    stockZone: _emberData.default.attr('number', { readOnly: true }),
    seasonalSales: _emberData.default.attr('number', { readOnly: true }),
    quarterlySales: _emberData.default.attr('number', { readOnly: true }),
    annualSales: _emberData.default.attr('number', { readOnly: true }),
    computedSales: _emberData.default.attr('number', { readOnly: true }),
    salesRank: _emberData.default.attr('number', { readOnly: true }),
    quantityBackordered: _emberData.default.attr('number', { readOnly: true }),
    bins: _emberData.default.hasMany('bins'),
    binSort: ['pickSection', 'location.length', 'location'],
    sortedBins: Ember.computed.sort('bins', 'binSort'),
    itemAssets: _emberData.default.hasMany('item-asset'),
    restockCasesNeeded: _emberData.default.attr('number', { readOnly: true }),
    binCount: Ember.computed('bins.@each.location', function () {
      return this.get('bins.length');
    }),
    receivingBins: Ember.computed('stockCases', 'standardBins', 'overstockBins', function () {
      if (this.get('stockCases')) {
        console.log('Stock Cases');
        console.log(this.get('overstockBins'));
        console.log(this.get('overstockBins').filterBy('canReceive', true));
        return this.get('overstockBins').filterBy('canReceive', true);
      } else {
        console.log('Do not stock cases');
        return this.get('standardBins').filterBy('canReceive', true);
      }
    }),
    binLocation: Ember.computed.alias('bin.location'),
    binChecked: 'lastMissingAt',
    bin: Ember.computed('cases', 'standardBins.@each.quantity', 'standardBins.@each.lastMissingAt', 'standardBins.@each.confirmedMissingAt', function () {

      var _this = this;
      console.log('Recalculate bin for ' + this.get('sku'));
      return this.get('sortedBins').sort(function (a, b) {

        if (b.get(_this.get('binChecked')) && !a.get(_this.get('binChecked'))) {
          return -1;
        }
        if (a.get(_this.get('binChecked')) && !b.get(_this.get('binChecked'))) {
          return 1;
        }

        if (!_this.get('cases')) {
          if ((a.get('quantity') || 0) < 2 && (b.get('quantity') || 0) > 1) {
            return -1;
          }
          if ((a.get('quantity') || 0) > 1 && (b.get('quantity') || 0) < 2) {
            return 1;
          }
        }

        if (a.get('pickSection') < b.get('pickSection')) {
          return -1;
        }
        if (a.get('pickSection') > b.get('pickSection')) {
          return 1;
        }
        if (a.get('location.length') < b.get('location.length')) {
          return -1;
        }
        if (a.get('location.length') > b.get('location.length')) {
          return 1;
        }
        if (a.get('location') < b.get('location')) {
          return -1;
        }
        if (a.get('location') > b.get('location')) {
          return 1;
        }

        return 0;
      })[0];
    }),
    stockingLocation: Ember.computed.alias('stockingBin.location'),
    stockingBin: Ember.computed('cases', 'bins.@each.quantity', 'bins.@each.lastMissingAt', 'bins.@each.confirmedMissingAt', 'bins.@each.sku', function () {

      // let _this = this;
      console.log('Recalculate bin for ' + this.get('sku'));
      var sb = this.get('sortedBins').filterBy('fixed', true).filterBy('attemptClear', false).sort(function (a, b) {

        if (b.get('standard') && !a.get('standard')) {
          return 1;
        }
        if (a.get('standard') && !b.get('standard')) {
          return -1;
        }

        // if (!_this.get('cases')) {
        //   if ((a.get('quantity') || 0) < 2 && (b.get('quantity') || 0) > 1) {
        //     return -1;
        //   }
        //   if ((a.get('quantity') || 0) > 1 && (b.get('quantity') || 0) < 2) {
        //     return 1;
        //   }
        // }

        if (a.get('location') < b.get('location')) {
          return -1;
        }
        if (a.get('location') > b.get('location')) {
          return 1;
        }
        if (a.get('location.length') < b.get('location.length')) {
          return -1;
        }
        if (a.get('location.length') > b.get('location.length')) {
          return 1;
        }
        if (a.get('location') < b.get('location')) {
          return -1;
        }
        if (a.get('location') > b.get('location')) {
          return 1;
        }

        return 0;
      })[0];
      if (sb) {
        console.log(this.get('sku') + ': ' + sb.get('location'));
      }
      return sb;
    }),
    stockingBins: Ember.computed.filterBy('bins', 'valid', true),
    standardBins: Ember.computed.filterBy('bins', 'standard', true),
    pickingBins: Ember.computed.filterBy('standardBins', 'lastMissingAt', null),
    overstockBins: Ember.computed.filterBy('bins', 'overstock', true),
    fixedBins: Ember.computed.filterBy('bins', 'fixed', true),
    restockDirectly: Ember.computed('overstockBins.@each.restockDirectly', function () {
      var restock = false;
      this.get('overstockBins').forEach(function (i) {
        if (i.get('restockDirectly')) {
          restock = true;
        }
      });
      return restock;
    }),
    stockBin: Ember.computed('bins.@each.id', function () {
      return this.get('overstockBins.lastObject') || this.get('standardBins.firstObject');
    }),
    relatedItems: _emberData.default.hasMany('related-item', { inverse: 'item' }),
    catalogItems: _emberData.default.hasMany('catalog-item'),
    tags: _emberData.default.hasMany('tag'),
    catalogListings: _emberData.default.hasMany('catalog-listing'),
    catalogItem: Ember.computed('catalogItems', function () {
      return this.get('catalogItems.firstObject');
    }),
    details: _emberData.default.attr({ readOnly: true }),
    discounts: _emberData.default.hasMany('quantity-discount', { async: true }),
    customerItems: _emberData.default.hasMany('customer-item'),
    orderedItems: _emberData.default.attr({ readOnly: true }),
    getOrderedItems: (0, _emberApiActions.memberAction)({ path: 'ordered_items', type: 'get' }),
    missingCount: (0, _emberApiActions.memberAction)({ path: 'missing_count', type: 'put' }),
    assignBin: (0, _emberApiActions.memberAction)({ path: 'assign_bin', type: 'put' }),
    inventory: (0, _emberApiActions.memberAction)({ path: 'inventory', type: 'put' }),
    cubiscan: (0, _emberApiActions.memberAction)({ path: 'cubiscan', type: 'put' }),
    getBackorders: (0, _emberApiActions.memberAction)({ path: 'backorders', type: 'get' }),
    getPurchaseOrderItems: (0, _emberApiActions.memberAction)({ path: 'purchase_order_items', type: 'get' }),
    getSalesChart: (0, _emberApiActions.memberAction)({ path: 'sales_chart', type: 'get' }),
    getLocationHistory: (0, _emberApiActions.memberAction)({ path: 'location_history', type: 'get' }),
    printLabels: (0, _emberApiActions.memberAction)({ path: 'print_labels', type: 'put' }),
    available: Ember.computed('onHand', 'reserved', function () {
      return Math.max(this.get('onHand') - (this.get('reserved') || 0), 0);
    }),
    quantityAvailable: Ember.computed('onHand', 'reserved', 'quantityCommitted', 'specialCommitted', 'backorderCommitted', function () {
      return this.get('available') - Math.max(0, this.get('quantityCommitted') || 0) - Math.max(0, this.get('backorderCommitted') || 0);
    }),
    imageBase: Ember.computed('fullImage', function () {
      if (this.get('fullImage')) {
        return '//dkewhs09r9f5z.cloudfront.net/products';
      }
      return '//d2mk6k62owtk8p.cloudfront.net/products/LF';
    }),
    imageUrl: Ember.computed('sku', function () {
      var sku = this.get('sku');
      if (this.get('fullImage')) {
        return this.get('imageBase') + '/50/' + sku + '.jpg';
      } else if (sku) {
        return this.get('imageBase') + '/50/' + sku.slice(0, 1) + '/' + sku + '.jpg';
      }
    }),

    largeImageUrl: Ember.computed('sku', function () {
      var sku = this.get('sku');
      if (this.get('fullImage')) {
        return this.get('imageBase') + '/200/' + sku + '.jpg';
      } else if (sku) {
        return this.get('imageBase') + '/200/' + sku.slice(0, 1) + '/' + sku + '.jpg';
      }
    }),
    maxImageUrl: Ember.computed('sku', function () {
      var sku = this.get('sku');
      if (this.get('fullImage')) {
        return this.get('imageBase') + '/x/' + sku + '.jpg';
      } else if (sku) {
        return this.get('imageBase') + '/x/' + sku.slice(0, 1) + '/' + sku + '.jpg';
      }
    }),
    keypadValue: 0,
    keypadVisible: false,
    changed: Ember.computed('hasDirtyAttributes', 'catalogItems.@each.hasDirtyAttributes', 'categoryItems.@each.hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') || this.get('catalogItems').filterBy('hasDirtyAttributes', true).length > 0 || this.get('categoryItems').filterBy('hasDirtyAttributes', true).length > 0;
    }),
    buttonText: Ember.computed('changed', function () {
      if (this.get('saveStarted')) {
        return 'Saving Item';
      } else if (this.get('changed')) {
        return 'Save Item';
      } else {
        return 'Item Saved';
      }
    }),
    hasDiscounts: Ember.computed('discounts', function () {
      return this.get('discounts.length') > 0;
    }),
    stockLocation: Ember.computed('location', function () {
      return this.get('location').split(' ').slice(-1);
    }),
    tempLocation: Ember.computed('tempBin.location', function () {
      console.log('temp: ' + this.get('sku') + ': ' + this.get('tempBin.location'));
      return this.get('tempBin.location');
    }),
    tempLocationSort: Ember.computed('tempBin.location', function () {
      return this.get('tempBin.location') || 'ZZZZZZ';
    }),
    sortedDiscounts: Ember.computed.sort('discounts', 'sortDiscounts'),
    sortDiscounts: ['level'],
    keypadTarget: 'width',
    nextShelfBin: (0, _emberApiActions.memberAction)({ path: 'next_shelf_bin', type: 'put' }),
    report: (0, _emberApiActions.collectionAction)({ path: 'report', type: 'getst' }),
    recommitInventory: (0, _emberApiActions.collectionAction)({ path: 'recommit_inventory', type: 'get' }),
    autoComplete: (0, _emberApiActions.collectionAction)({ path: 'ac', type: 'get' }),
    orderQuantity: Ember.computed('onHand', 'quantityCommitted', 'reorderPoint', 'moq', 'lastOrderedQuantity', function () {

      if (this.get('lastOrderedQuantity') > 0) {}
      if (this.get('moq') > 0 && this.get('lastOrderedQuantity') + this.get('onHand') - this.get('quantityCommitted') <= this.get('reorderPoint')) {
        return Math.ceil((1 + this.get('reorderPoint') + this.get('quantityCommitted') - (this.get('lastOrderedQuantity') + this.get('onHand'))) / this.get('moq')) * this.get('moq');
      } else {
        return 0;
      }
    }),
    makeup: Ember.computed('sku', function () {
      return ['MR', 'TT', '54', 'ZO', 'PG'].indexOf(this.get('sku').slice(0, 2)) > -1;
    })

  });
});