define('loftus/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    application: _emberData.default.attr('string'),
    event: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    priority: _emberData.default.attr('number'),
    readAt: _emberData.default.attr('date'),
    deletedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    user: _emberData.default.belongsTo('user'),
    model: _emberData.default.attr('string'),
    modelId: _emberData.default.attr('number'),
    url: _emberData.default.attr('string'),
    urlTitle: _emberData.default.attr('string'),
    sound: _emberData.default.attr('string'),
    route: _emberData.default.attr('string'),
    routeTitle: _emberData.default.attr('string'),
    splash: Ember.computed('priority', function () {
      return !this.get('priority') || this.get('priority') && this.get('priority') > 0;
    }),
    snooze: _emberData.default.attr('boolean')

  });
});