define('loftus/models/call', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    direction: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    contactName: _emberData.default.attr('string'),
    purpose: _emberData.default.attr('string'),
    result: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    startedAt: _emberData.default.attr('date'),
    duration: _emberData.default.attr('number'),
    secureUrl: _emberData.default.attr('string', { readOnly: true }),
    transcribed: _emberData.default.attr({}, { readOnly: true }),
    durationObserver: Ember.observer('durationMinutes', 'durationSeconds', function () {
      this.set('duration', parseInt(this.get('durationMinutes') || 0, 10) * 60 + parseInt(this.get('durationSeconds') || 0, 10) * 1);
    }),
    durationDisplay: Ember.computed('duration', function () {
      var output = [];
      if (!this.get('duration')) {
        return 'unknown';
      }
      if (this.get('duration') > 59) {
        output.push(Math.floor(this.get('duration') / 60) + ' min');
      }
      if (this.get('duration') % 60 > 0) {
        output.push(this.get('duration') % 60 + ' sec');
      }
      return output.join(' ');
    }),
    detailsUrl: Ember.computed('uuid', function () {
      return 'https://voice.loftus.com/app/xml_cdr/xml_cdr.php?submit=Search&uuid=' + this.get('uuid');
    }),
    billable: _emberData.default.attr('boolean'),
    phone: _emberData.default.attr('string'),
    uuid: _emberData.default.attr('string'),
    createdById: _emberData.default.attr('number'),
    updatedById: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    zohoId: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    customer: _emberData.default.belongsTo('customer', { inverse: 'calls' }),
    contact: _emberData.default.belongsTo('contact'),
    lead: _emberData.default.belongsTo('lead'),
    entity: Ember.computed('contact', 'lead', function () {
      if (this.get('contact.id')) {
        return this.get('contact');
      } else if (this.get('lead.id')) {
        return this.get('lead');
      }
      return this.get('customer');
    }),
    timeEntry: Ember.computed('hasDirtyAttributes', 'duration', function () {
      var changed = this.changedAttributes();
      if (!this.get('duration') || changed['duration']) {
        return true;
      }
    })
  });
});